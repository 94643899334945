.TranscriptViewFilter {
  display: flex;
  color: maroon;

  > * {
    display: flex;
    margin-right: 1.5em;
    font-weight: bold;
  }

  &--Label {
    color: black;
    margin-right: 0.5em;
  }

  #TranscriptViewFilter--Toggle,
  #InBasketFilter--Toggle {
    margin-right: 0.5em;
  }
}

.TranscriptResultTableButton .ResultTableButton .fa {
  color: #3e3e3e;
}

.MesaComponent {
  .Toolbar {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    padding: 0;

    button {
      // margin: 10px;
      // padding: 10px;
      .icon:first-child {
        margin-right: 10px;
      }
      .icon:last-child {
        margin-left: 10px;
      }
    }
  }
}

.PreferredOrganismsConfig--ToggleHelp {
  display: grid;
  gap: 0.5em;
  grid-auto-flow: column;

  position: relative;
  max-width: 30em;

  padding: 1.125em 1.5em;
  border: 0.0625rem solid #bbb;
  border-radius: 1rem;
  box-shadow: 0 0.125rem 0.5rem rgba(0, 0, 0, 0.3),
    0 0.0625rem 0.125rem rgba(0, 0, 0, 0.2);

  transition: all 250ms ease-in-out;
  opacity: 1;

  &::after {
    font-family: 'FontAwesome';
    content: '\f176';
    position: absolute;
    right: 2.83em;
    bottom: calc(100% + 0.25em);
    color: #777;
  }

  .fa-lightbulb-o {
    position: relative;
  }

  .fa-lightbulb-o {
    font-size: 1.5em;
  }

  .fa-lightbulb-o::after {
    content: url('./LightbulbContents.svg');
    position: absolute;
    top: 0;
    left: -0.214em;
    margin: 0 auto;
    display: block;
    width: 1em;
  }

  &__hidden {
    opacity: 0;
  }
}

.wdk-RealTimeSearchBox {
  white-space: nowrap;
  position: relative;
  width: 100%;
}

.wdk-RealTimeSearchBox__withHelp {
  width: calc(100% - 1em);
}

.wdk-RealTimeSearchBoxInput {
  width: calc(100% - 2em);
  vertical-align: middle;
  padding: 6px 2em 6px 6px;
}

.wdk-RealTimeSearchBoxLabel {
  width: calc(100% - 10px);
  display: inline-flex;
}

/** Lighten search box placeholder */
/*
input.wdk-RealTimeSearchBoxInput::-webkit-input-placeholder { color:#888; }
input.wdk-RealTimeSearchBoxInput::-moz-placeholder { color:#888; }
input.wdk-RealTimeSearchBoxInput:-ms-input-placeholder { color:#888; }
input.wdk-RealTimeSearchBoxInput:-moz-placeholder { color:#888; }
*/

.wdk-RealTimeSearchBoxSearchIcon,
button.wdk-RealTimeSearchBoxCancelBtn {
  position: absolute;
  right: 1.7em;
  font-size: 1em;
  color: #999999;
  align-self: center;
}

.wdk-RealTimeSearchBox__active .wdk-RealTimeSearchBoxSearchIcon,
.wdk-RealTimeSearchBox__inactive button.wdk-RealTimeSearchBoxCancelBtn {
  display: none;
}

button.wdk-RealTimeSearchBoxCancelBtn {
  border: 0;
  background: transparent !important;
  outline-offset: -4px;
  padding: 0;
  height: 100%;
}

.wdk-RealTimeSearchBox .HelpTrigger {
  margin: 0 8px !important;
}

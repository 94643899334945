$hamburger-width: 1013px;

@mixin shadowed {
  text-shadow: 1px 1px 1px #00000052, -1px -1px 1px #00000052,
    1px -1px 1px #00000052, -1px 1px 1px #00000052, 2px 2px 2px #00000052,
    -2px -2px 2px #00000052, 2px -2px 2px #00000052, -2px 2px 2px #00000052;
}

.ebrc-Header {
  position: fixed;
  width: 100%;
  height: 11em;

  display: grid;
  grid-template-areas:
    'site-branding .         project-branding project-branding'
    'site-branding searchbar .                .     '
    'site-branding menubar   social           login          ';
  grid-template-columns: auto 1fr auto;

  align-items: center;

  @media screen and (max-width: $hamburger-width) {
    flex-direction: column;
    height: auto;
    position: relative;
  }

  &ProjectBranding {
    grid-area: project-branding;
    justify-self: flex-end;

    margin: 0.5em;

    content: url('../../../images/project-branding.png');

    @media screen and (max-width: $hamburger-width) {
      display: none;
    }
  }

  &BrandingContainer {
    grid-area: site-branding;
    margin-left: 1em;

    @media screen and (max-width: $hamburger-width) {
      width: 100%;
      margin-left: 0;
    }
  }

  &Hamburger {
    grid-area: hamburger;

    display: none;
    justify-content: center;
    align-items: center;
    font-size: 2em;
    border: none;
    padding: 0.25em 0.5em;

    height: 2.3em;
    color: white;
    background: transparent;

    &:focus,
    &:active,
    &:hover {
      background: transparent;
      border: none;
    }

    @media screen and (max-width: $hamburger-width) {
      display: flex;
    }
  }

  &MenuBar {
    grid-area: menubar;
    justify-self: center;

    @media screen and (max-width: $hamburger-width) {
      display: none;

      &__hamburger-shown {
        display: block;
        width: calc(100% - 1em);
      }
    }
  }

  &MenuBar > &MenuItemGroup {
    display: flex;

    > .ebrc-HeaderMenuItemContent {
      padding: 0.5em;
      font-size: 1.2em;

      > a,
      .ebrc-HeaderSubmenuGroup > a {
        @include shadowed;
        color: #fafafa;
        font-weight: bold;

        &:focus,
        &:active,
        &:hover {
          text-decoration: none;
        }
      }
    }

    @media screen and (max-width: $hamburger-width) {
      flex-direction: column;
    }
  }

  &MenuItemContent {
    white-space: nowrap;

    summary {
      cursor: pointer;

      &::marker {
        color: #aaa;
      }

      &::-webkit-details-marker {
        color: #aaa;
      }

      &:hover {
        > span {
          text-decoration: underline;
        }
      }

      &:focus,
      &:hover {
        outline: none;
      }
    }
  }

  &SubmenuGroup {
    position: relative;
    color: black;

    a {
      color: black;
    }

    &__selected {
      z-index: 10;

      > a::after {
        position: absolute;
        width: 0;
        height: 0;
        border-left: 1em solid transparent;
        border-right: 1em solid transparent;
        border-bottom: 1em solid #ededed;
        content: '';
        left: 50%;
        margin-left: -1em;
        top: 2em;
        z-index: 10;

        @media screen and (max-width: $hamburger-width) {
          display: none;
        }
      }
    }

    .ebrc-HeaderMenuItemGroupContainer {
      position: absolute;

      .ebrc-HeaderMenuItemGroup {
        margin-top: 1.5em;
        background-color: #ededed;
        padding: 0.125em 0.25em;
        box-shadow: 0 0 1em rgba(0, 48, 76, 0.5);
        overflow-y: auto;
        max-height: 80vh;
      }

      .ebrc-HeaderMenuItemContent {
        padding: 0.625em 1em;
      }

      details {
        > .ebrc-HeaderMenuItemContent {
          display: list-item;

          font-size: 0.9em;

          padding: 0.25em 0;
          margin-left: 2.5em;
          color: #aaa;
        }
      }

      @media screen and (max-width: $hamburger-width) {
        position: relative;

        .ebrc-HeaderMenuItemGroup {
          max-height: unset;
        }
      }
    }
  }

  .SiteSearch--SearchBox {
    grid-area: searchbar;
    font-size: 1.2em;
    margin-left: 0.5em;
    margin-right: 0.5em;
    justify-self: center;
    width: 37em;

    ::placeholder {
      font-style: italic;
    }

    @media screen and (max-width: $hamburger-width) {
      width: calc(100% - 0.5em);
      margin: 0;
      max-width: 550px;
    }
  }

  .ebrc-SocialMediaLinks {
    grid-area: social;
    justify-self: flex-end;
    height: 2.8125em;

    > a {
      @include shadowed;
      margin: 0.5em;
      color: white;
    }
  }

  .UserMenu {
    grid-area: login;
    justify-self: flex-end;
    text-align: center;
    height: 4.5em;

    margin-right: 0.5em;
    padding: unset;

    &-Pane {
      background-color: #ededed;
      box-shadow: 0 0 1em rgba(0, 48, 76, 0.5);
      z-index: 10;
    }

    &-Icon {
      @include shadowed;
      opacity: 0.8;
    }

    span.UserMenu-Title {
      @include shadowed;
      opacity: 0.8;
      font-weight: bold;
      max-width: 7em;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
}

$red: #cc0000;
$gray: #f5f5f5;

.PreferredOrganismsConfig {
  > p {
    font-size: 1.4em;
    margin-bottom: 2em;
  }

  h1 {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &--ToggleHelp {
    font-size: 1rem;
  }

  &--Instructions {
    display: flex;
    justify-content: space-between;
  }

  &--Main {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 1em;
    align-items: flex-start;
  }

  h2 {
    padding-bottom: 1rem;
  }

  &--Selections {
    h2 {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }

    .wdk-CheckboxTreeLinks {
      display: grid;
      grid-template-rows: 1fr;
      align-items: flex-end;
      height: 2.22222222222em;
    }

    .wdk-CheckboxTreeLinks > div {
      text-align: left;
    }

    .wdk-CheckboxTreeFilters {
      grid-template-columns: 33em auto;
      gap: 0.5em;
    }

    .wdk-CheckboxTree .wdk-RealTimeSearchBox__withHelp {
      width: calc(33em - 2rem);
    }
  }

  &--Preview {
    h2 {
      margin-left: 1rem;
    }
  }

  &--ConfigButtons {
    position: absolute;
    left: 14.5em;
    font-weight: bold;
    transition: all 250ms ease-in-out;
    white-space: nowrap;

    &__visible {
      opacity: 1;
    }

    &__hidden {
      opacity: 0;
    }
  }

  &--ConfigButtons > button.btn {
    color: white;
    font-size: 1rem;
    margin: 0 1rem 0 0;
  }

  &--ConfigButtons__hidden > button:disabled {
    cursor: default;
  }

  &--ApplyButton.btn {
    background: green;

    &:hover,
    &:focus {
      background: green;
    }

    &:disabled {
      opacity: 0.5;
    }
  }

  &--CancelButton.btn {
    background: #b71c1c;

    &:hover,
    &:focus {
      background: #b71c1c;
    }

    &:disabled {
      opacity: 0.5;
    }
  }

  &--PreviewContent {
    background-color: $gray;
    border: 0.125rem solid #ccc;
    border-radius: 0.5rem;
    padding: calc(1.5em - 0.2025rem) 1.5em 1.5em 1.5em;

    .wdk-CheckboxTree {
      padding: 0.5em;
    }
  }

  &--PreviewInstructions {
    font-size: 1.3em;
  }

  &--SelectionCount__empty {
    color: $red;
  }

  &--NoPreferencesSelected {
    font-size: 1.2em;
    font-style: italic;
    color: $red;
    padding: 1em;
  }

  h2 {
    font-weight: 400;
  }

  &--PreviewTag {
    font-style: italic;
  }

  &--InlineTitle {
    font-weight: bold;
  }
}

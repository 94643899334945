.PreferredOrganismsConfig--Toggle {
  color: black;
  display: grid;
  gap: 0.5em;
  grid-template-columns: 1fr 3.8em;
  align-items: center;
  justify-items: flex-start;

  margin-right: 1em;

  .wdk-Icon {
    font-size: 1.3em;
  }
}

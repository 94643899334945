.filter-param {
  width: 100%;
}
.filter-param h3 {
  font-size: 1.2em;
  font-weight: bold;
}
.filter-param p {
  font-style: initial;
  margin: 4px;
}
.filter-param .muted {
  color: #9f9f9f;
}
.filter-param .hidden {
  display: none;
}
.filter-param .toggle-links {
  font-size: smaller;
  margin: 0;
}
.filter-param .filter-items-wrapper {
  min-height: 2em;
  display: flex;
  align-items: center;
  font-size: 1.2em;
}
.filter-list-total,
.filter-list-selected {
  font-weight: 500;
}
.filter-list-total {
  width: 25%;
  padding-left: 2em;
  margin-right: 1em;
}
.filter-param,
.filter-param * {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
.filter-param .ui-tabs {
  border: none;
  background: transparent;
}
.filter-param .collapse {
  position: absolute;
  right: 1em;
  top: 5px;
}
.filter-param .collapse-wrapper {
  display: inline-block;
  position: absolute;
  top: 0.5em;
  left: 50%;
  z-index: 1;
}
.filter-param .ui-tabs-panel {
  padding: 0;
  box-shadow: 1px 1px #ddd;
  background-color: white;
}
.filter-param .filter-view {
  position: relative;
}
.filter-param .range-filter .head {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
}
.filter-param .range-filter .head input {
  width: 8em;
  text-align: right;
}
.filter-param .range-filter .table tr {
  display: inline-block;
}
.filter-param .range-filter {
  position: relative;
}
.filter-param .range-filter dt {
  font-weight: bold;
}
.filter-param .range-filter dt:after {
  content: ':';
}
.filter-param .range-filter .selection-total {
  color: #666666;
  float: right;
}
.filter-param .range-filter .include-unknown {
  margin-left: 1.5em;
}
.filter-param .range-filter .wdk-NumberRangeSelector {
  display: inline-block;
}
.filter-param .chart-container {
  position: relative;
  padding: 1em;
  margin-top: 1em;
  min-width: 200px;
}
.filter-param .chart {
  height: 200px;
  margin-left: 2em;
  width: 100%;
}
.filter-param .chart-title {
  font-weight: 500;
}
.filter-param .chart-title.x-axis {
  text-align: center;
  padding-bottom: 1em;
}
.filter-param .chart-title.y-axis {
  width: 200px;
  position: absolute;
  text-align: center;
  left: -190px;
  top: 0;
  -webkit-transform: rotate(-90deg);
  -moz-transform: rotate(-90deg);
  -ms-transform: rotate(-90deg);
  -o-transform: rotate(-90deg);
  transform: rotate(-90deg);
  -webkit-transform-origin: top right;
  -moz-transform-origin: top right;
  -ms-transform-origin: top right;
  -o-transform-origin: top right;
  transform-origin: top right;
}
.filter-param .chart-controls {
  background-color: #f9f9f9;
  border: 1px solid #e9e9e9;
  border-radius: 0.5em;
  font-size: 0.9em;
}
.filter-param .chart-controls .chart-controlsHeader {
  padding: 1em;
}
.filter-param .chart-controls .chart-controlsContent {
  display: flex;
  align-items: stretch;
  flex-wrap: wrap;
  overflow-x: auto;
}
.filter-param .chart-controls .chart-controlsContent em {
  color: #444444;
  margin-left: 1em;
}
.filter-param .chart-controls .chart-controlsContent .range-warning {
  color: #d32f2f;
}
.filter-param .chart-controls .chart-controlsContent > * {
  margin: 0 1em 1em 1em;
  flex: 1 0 0;
  position: relative;
  padding-bottom: 4em;
}
.filter-param .chart-controls .chart-controlsContent > * > div {
  position: absolute;
  bottom: 1em;
}
.filter-param .chart-controls fieldset legend {
  font-weight: 500;
  padding-inline-start: 0.5em;
  padding-inline-end: 0.5em;
}
.filter-param .chart-controls td,
.filter-param .chart-controls th {
  border: none;
  white-space: nowrap;
}
.filter-param .chart-controls td {
  width: 100%;
}
.filter-param .chart-controls th {
  text-align: right;
}
.filter-param .chart-controls input {
  vertical-align: middle;
}
.filter-param .chart-controls input[type='number'] {
  width: 8em;
}
.filter-param .filters,
.filter-param .filters-server-side {
  position: relative;
  min-height: 36em;
}
.filters {
  display: flex;
}
.filter-param .filters-server-side {
  border: 1px solid #999;
}
.filter-param .field-list {
  width: 25%;
  padding-left: 8px;
  padding-right: 8px;
}
.filter-param .wdk-AttributeFilterFieldParent,
.filter-param .wdk-AttributeFilterFieldItem {
  font-weight: bold;
  position: relative;
  white-space: normal;
  display: block;
}
.filter-param .wdk-AttributeFilterFieldParent,
.filter-param .wdk-AttributeFilterFieldItem,
.filter-param .wdk-CheckboxTreeToggle {
  padding: 4px;
}
.filter-param .wdk-AttributeFilterFieldItem__active {
  background: #eaeaea;
}
.filter-param .field-detail {
  width: 75%;
  padding: 0 1rem;
}
.filter-param .unknown-count {
  font-size: 1.1em;
  font-weight: 300;
}
.filter-param .field-detail__topPadding {
  padding-top: 2.5em;
}
.filter-param .field-detail__fullWidth {
  width: 100%;
  margin: 0;
  padding: 0;
}
.filter-param .field-detail h4 {
  margin-top: 4em;
}
.filter-param .field-detail .apply-filter {
  text-align: right;
  padding: 1em 0;
}
.filter-param .field-detail .description p {
  margin: 0;
  text-overflow: ellipsis;
  overflow: hidden;
  vertical-align: text-bottom;
  white-space: nowrap;
  display: inline-block;
  width: 90%;
}
.filter-param .field-detail .description p.expanded {
  white-space: normal;
}
.filter-param .field-detail .description .read-more {
  display: none;
}
.filter-param .field-detail .description .read-more.visible {
  display: inline-block;
  vertical-align: top;
}
.filter-param .membership-filter,
.filter-param .range-filter {
  margin-top: 1em;
  min-height: 20em;
  clear: both;
}
.filter-param .membership-filter .MesaComponent .DataTable,
.filter-param .wdk-MultiFieldFilter .MesaComponent .DataTable {
  max-width: 100%;
  font-size: 1em;
}
.filter-param .wdk-MultiFieldFilterUpdateCountsButton {
  position: absolute;
  top: 0;
  right: 1em;
}
.filter-param .membership-filter .HeadingRow:first-child > th {
  border-top: 1px solid #ccc;
}
.filter-param .membership-filter .HeadingRow:last-child > th {
  border-bottom: 1px solid #ccc;
}
.filter-param .membership-filter .DataRow,
.filter-param .membership-filter .HeadingRow {
  background: white;
}
.filter-param .membership-filter .MesaComponent th,
.filter-param .membership-filter .MesaComponent td {
  background: transparent;
  padding: 4px;
  border: none;
  position: relative;
}
.filter-param .membership-filter .MesaComponent th {
  border-right: 1px solid #ccc;
  background-color: #f7f7f7;
  background-clip: padding-box; /* prevent firefox from clipping border */
  vertical-align: bottom;
}
.filter-param .membership-filter .MesaComponent th:first-child {
  border-left: 1px solid #ccc;
}
.filter-param .membership-filter .MesaComponent th:first-child,
.filter-param .membership-filter .MesaComponent th:last-child {
  border-radius: 0;
}
.filter-param .membership-filter .MesaComponent .HeadingCell-Content {
  align-items: flex-end;
}
.filter-param
  .membership-filter
  .MesaComponent
  th:nth-child(3)
  .HeadingCell-Content,
.filter-param
  .membership-filter
  .MesaComponent
  th:nth-child(4)
  .HeadingCell-Content {
  justify-content: flex-end;
}
.filter-param
  .membership-filter
  .MesaComponent
  th:nth-child(3)
  .HeadingCell-Content-Label,
.filter-param
  .membership-filter
  .MesaComponent
  th:nth-child(4)
  .HeadingCell-Content-Label {
  margin-left: auto;
  text-align: center;
}
/* Numeric columns (All and Matching) */
.filter-param
  .membership-filter
  .MesaComponent
  .HeadingRow:last-child
  th:nth-child(3),
.filter-param
  .membership-filter
  .MesaComponent
  .HeadingRow:last-child
  th:nth-child(4),
.filter-param .membership-filter .MesaComponent td:nth-child(3),
.filter-param .membership-filter .MesaComponent td:nth-child(4) {
  text-align: right;
  /* padding-right: 1rem;
  padding-left: 1rem; */
  white-space: nowrap;
}
.filter-param .membership-filter .MesaComponent tr:hover td {
  background: #fafafa;
}
.filter-param .membership-filter .bar {
  top: 2px;
  padding-left: 0.5em;
}
.filter-param .membership-filter .text,
.filter-param .membership-filter .distribution {
  display: inline-block;
}
.filter-param .membership-filter .text {
  width: 50%;
}
.filter-param .membership-filter .distribution {
  width: 9%;
}

.filter-param .membership-actions {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 1em 0;
}
.filter-param .membership-action__no-filters,
.filter-param .membership-action__group-selected {
  font-weight: 300;
  font-size: 1.1em;
}
.filter-param .membership-action__group-selected {
  display: flex;
  align-items: center;
}
.filter-param .membership-group-selected-button {
  border-radius: 4px;
  padding: 0.5em;
  width: 3em;
}
.filter-param .membership-group-selected-button:focus {
  outline-offset: -3px;
}
.filter-param .membership-group-selected-button__on {
  background: #3d78d8;
  color: white;
  border-color: white;
}
.filter-param .membership-group-selected-button__on:hover {
  background: #4289d9;
}
.filter-param .membership-group-selected-button__off {
  background: #f3f3f3;
  color: black;
  border-color: #bbb;
}
.filter-param .membership-group-selected-button__off:hover {
  background: white;
}
.filter-param .membership-group-selected-label {
  margin-right: 0.5em;
  font-weight: bold;
}
.filter-param .membership-table-panel {
  max-height: 500px;
  overflow-y: auto;
  box-shadow: 0 -1px 1px rgba(0, 0, 0, 0.12);
  margin-top: 2px;
}
.filter-param table {
  width: 100%;
}

.filter-param .membership-filter .member {
  margin: 0.4em 0;
  height: 1em;
}
.filter-param .membership-filter input[name='value'] {
  margin-right: 0.4em;
}
.filter-param .membership-filter .value {
  min-width: 8em;
  width: 100%;
  display: inline-block;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow-x: hidden;
  vertical-align: top;
}
.filter-param .membership-filter .percentage {
  font-size: 0.8em;
}
.filter-param .membership-filter .counts {
  display: flex;
  justify-content: center;
  align-items: baseline;
}
.filter-param .membership-filter .counts-value,
.filter-param .membership-filter .counts-percentage {
  margin: 0 0.5em;
}
.filter-param .membership-filter .counts-percentage {
  font-size: 0.8em;
}
/* Don't recall if/why this was needed - dmf
.membership-filter .frequency {
  width: 3em;
  display: inline-block;
}
*/
.filter-param .bar {
  height: 1em;
  width: 99%;
  position: relative;
}
.filter-param .bar .fill {
  color: white;
  height: 1em;
  position: absolute;
  min-width: 1px;
}
.filter-param .filter-param-legend {
  float: right;
  padding: 0;
  margin: 0.5em 1em;
  color: #666666;
  border: 1px solid #dadada;
  border-radius: 4px;
}
.filter-param .filter-param-legend > div {
  margin: 0.5em 0;
}
.filter-param .filter-param-legend .bar {
  display: inline-block;
  margin: 0;
  margin-left: 1em;
}
.filter-param .filter-param-legend .label {
  display: inline-block;
  padding: 0 0.4em;
}
.filter-param .filter-param-legend .bar {
  width: 10px;
  height: 10px;
}
.filter-param .filter-param-legend .bar .fill {
  width: 10px;
  height: 10px;
}
.filter-param .membership-filter .member.member__selected td {
  font-weight: bold;
  /*background-color: #66A4E7;*/
}
.filter-param .membership-filter .member.member__disabled td > * {
  opacity: 0.4;
}
.filter-param .membership-filter .HeadingRow th:first-child,
.filter-param .membership-filter .member td:first-child {
  /* Prevent checkbox cell of table from having scrollbar */
  padding: 4px;
  text-align: center;
  overflow: visible;
}
.filter-param .membership-filter .disabled-tooltip {
  position: fixed;
  color: black;
  background-color: #fffceb;
  padding: 4px;
  z-index: 1;
  box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.2);
  border-radius: 4px;
  width: 22em;
}
.filter-param .results {
  padding: 1em;
}
.filter-param .results > button {
  position: absolute;
  right: 16px;
}
.filter-param .results .table td {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.filter-param .results table.dataTable tr.odd {
  background-color: #ffffff;
}
.filter-param .results table.dataTable tr.even {
  background-color: #f6f6f6;
}
.filter-param .overview dt,
.overview dd {
  float: left;
}
.filter-param .overview dt {
  padding-right: 0.4em;
}
.filter-param .overview dd {
  padding-right: 0.8em;
  margin-left: 0;
}
.filter-param #applied-filters {
  text-align: center;
  min-height: 2em;
}
.filter-param .filter-items {
  line-height: 14px;
  margin-left: 0;
  font-size: 0.8em;
  display: inline-flex;
  align-items: center;
  flex-wrap: wrap;
}
.filter-param .filter-items .filter-items {
  font-size: 1em;
}
.filter-param .filter-items * {
  vertical-align: middle;
}
.filter-param .filter-items[data-total]:before {
  content: attr(data-total) ' selected ';
  font-weight: bold;
  line-height: 2.7em;
}
.filter-param .filter-items li {
  display: inline-flex;
  margin: 0.2em;
}
.filter-param .filter-items li.multiFilter {
  border-left: 2px solid #666666;
  border-right: 2px solid #666666;
  border-radius: 5px;
  flex-direction: column;
  padding: 0.4em 0.2em;
  position: relative;
  text-align: center;
}
.filter-param .filter-items li.multiFilter.selected {
  border-left: 3px solid black;
  border-right: 3px solid black;
  font-weight: 500;
}
.filter-param .filter-items li > .filter-item {
  background-color: #ececec;
  border-radius: 4px;
  border: 1px solid #c8c8c8;
  padding: 2px 4px;
}
.filter-param .filter-items li.selected .filter-item {
  border: 1px solid #909090;
  font-weight: 500;
}
.filter-param .filter-items .remove {
  display: inline-block;
  cursor: pointer;
  font-size: 1.5em;
  font-weight: normal;
  padding-left: 4px;
  opacity: 0.4;
  -ms-filter: 'progid:DXImageTransform.Microsoft.Alpha(Opacity=40)';
}
.filter-param .filter-items .remove:hover {
  opacity: 1;
  -ms-filter: 'progid:DXImageTransform.Microsoft.Alpha(Opacity=100)';
}
.filter-param .summary ul,
.filter-param .summary li {
  display: inline-block;
}
.filter-param .summary {
  margin-top: 4px;
}
.filter-param .filter-results ul {
  list-style: none;
  padding-left: 1em;
}
.wdk-AttributeFilter-Collapse {
  padding-left: 1em;
  cursor: pointer;
}
/*
.wdk-AttributeFilter-TabNav {
  display: inline-block;
  position: relative;
  top: 2px;
}
*/
.wdk-AttributeFilter-Loading {
  position: absolute;
  top: 65px;
  left: 30px;
}
.wdk-AttributeFilter-FilteredData {
  padding: 5px;
}
.wdk-AttributeFilter-ItemIgnored {
  color: #9f9f9f;
}

/** Field Selector **/
.wdk-AttributeFilter-FieldSelector {
  padding: 0 10px;
}

.wdk-AttributeFilter-FieldSelector ul {
  list-style: none;
  padding-left: 1em;
}

.wdk-AttributeFilter-FieldSelector h4 {
  margin: 10px 0 5px 0;
}

/** DateSelector overrides */
.filter-param .wdk-DateSelector {
  display: inline-block;
}

.filter-param .wdk-MultiFieldFilterRow > td {
  padding: 0.5em 1em;
}

.filter-param .wdk-MultiFieldFilter .HeadingRow > th,
.filter-param .wdk-MultiFieldFilterRow__summary > td {
  padding: 0.5em 1em;
  font-weight: 500;
  color: #444;
  border-top: 1px solid #dcdcdc;
  border-right: 1px solid #dcdcdc;
  border-bottom: 1px solid #dcdcdc;
  background: #f1f1f1;
  background-clip: padding-box;
}

.filter-param .wdk-MultiFieldFilterRow__summary > td {
  background: #f9f9f9;
}

.filter-param .wdk-MultiFieldFilterRow__selected {
  font-weight: bold;
}

.filter-param .wdk-MultiFieldFilterRow__value > td {
  border: none;
  background: white;
  background-clip: padding-box;
  padding: 4px;
}

.filter-param .wdk-MultiFieldFilterRow__last-value > td {
  padding-bottom: calc(1.5em);
}

.filter-param .wdk-MultiFieldFilterRow__disabled > td {
  opacity: 0.4;
}

.filter-param .wdk-MultiFieldFilterRow__value > td:first-child {
  border-left: none;
}

.filter-param .wdk-MultiFieldFilterRow__value > td:last-child {
  border-right: none;
}

.filter-param .wdk-MultiFieldFilterValueContainer > div {
  display: inline-block;
  /* width: 50%; */
}

.filter-param .wdk-MultiFieldFilterCountCell > div {
  display: inline-block;
  width: 50%;
  padding: 0 0.35em;
}
.filter-param .wdk-MultiFieldFilterCountCell > div:first-child {
  text-align: right;
}
.filter-param .wdk-MultiFieldFilterCountCell > div:last-child {
  text-align: left;
}

/* make Mesa's action bar as small as possible when not rendered */
.membership-filter
  > .Mesa.MesaComponent
  > .ActionToolbar.Toolbar
  > .ActionToolbar-Info {
  min-height: auto;
}

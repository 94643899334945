#eupathdb-PathwayRecord-cytoscape {
  position: relative;
}

#eupathdb-PathwayRecord-cytoscape .eupathdb-Menu,
#eupathdb-PathwayRecord-cytoscape .eupathdb-Submenu {
  background-color: white;
}

#eupathdb-PathwayRecord-cytoscape .eupathdb-Menu {
  width: auto;
  min-width: 0px;
  display: flex;
  flex-wrap: nowrap;
}

#eupathdb-PathwayRecord-cytoscape .eupathdb-MenuItem:hover {
  background-color: #f3f3f3;
}

#eupathdb-PathwayRecord-cytoscape .eupathdb-MenuItemText {
  color: black;
}

#eupathdb-PathwayRecord-cytoscape .eupathdb-MenuItemText__parent:after {
  color: rgba(0, 0, 0, 0.5);
}

.veupathdb-PathwayRecord-menuContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-top-right-radius: 4px;
  border-top-left-radius: 4px;
  border: 1px solid black;
}

.veupathdb-PathwayRecord-menuControls {
  font-size: 0.9em;
  position: relative;
  display: flex;
  align-items: center;
}

.veupathdb-PathwayRecord-menuControls > * {
  white-space: nowrap;
}

.veupathdb-PathwayRecord-ResetDisplayButton.btn {
  margin-left: 0.75em;
  margin-right: 0.75em;
}

.eupathdb-PathwayNodeDetailsContainer {
  position: absolute;
  top: 25px;
  left: 75px;
  min-width: 350px;
  min-height: 200px;
  border: 1px solid #999;
  border-radius: 4px;
  background: white;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.2);
}

.eupathdb-PathwayNodeDetailsHeader {
  font-weight: bold;
  font-size: 1.2em;
  cursor: move;
  position: relative;
  padding: 6px 12px;
  background: #ddd;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
}

.eupathdb-PathwayRecord-CytoscapeContainer {
  border: 1px solid black;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  border-top: none;
  width: 100%;
  height: 100%;
  height: calc(100vh - 100px);
  padding-bottom: 26px;
  box-sizing: border-box;
}

#eupathdb-PathwayRecord-generaSelector-wrapper {
  position: absolute;
  background: none;
  display: none;
}

#eupathdb-PathwayRecord-generaSelector {
  width: 620px;
}

#eupathdb-PathwayRecord-generaSelector .wdk-CheckboxListItem {
  display: inline-block;
  line-height: 1.5em;
  width: 200px;
  max-width: 625px;
}

#eupathdb-PathwayRecord-generaSelector .wdk-CheckboxListItem label {
  text-align: left;
}

#eupathdb-PathwayRecord-generaSelector .wdk-CheckboxListItem input {
  margin-right: 0.5em;
}

#eupathdb-PathwayRecord-generaSelector .hideMenu {
  float: right;
  width: 12px;
  cursor: pointer;
  margin-right: 1em;
}

#vis-menu {
  background: -webkit-linear-gradient(rgb(191, 191, 191), rgb(121, 121, 121));
  background: -o-linear-gradient(rgb(191, 191, 191), rgb(121, 121, 121));
  background: -moz-linear-gradient(rgb(191, 191, 191), rgb(121, 121, 121));
  background: linear-gradient(rgb(191, 191, 191), rgb(121, 121, 121));
  background-color: rgb(171, 171, 171);
  margin-bottom: inherit;
  width: auto;
}

#vis-menu li {
  z-index: 96;
}

.eupathdb-PathwayGraphSelector {
  min-width: 50em;
  max-width: 90vw;
  height: 80vh;
  padding: 0 3em;
}

.veupathdb-PathwaySearchByTerm {
  width: 30em;
  padding: 0 3em;
}

.veupathdb-PathwaySearchById {
  display: flex;
  align-items: center;
  width: 100%;
  padding: 0.5em 3em;
}

.veupathdb-SearchByIdDialog .wdk-DialogContent {
  overflow: visible;
}

.eupathdb-PathwayGeneraInfo {
  font-size: 110%;
  font-style: italic;
  margin: 10px 0;
}

.eupathdb-PathwayGeneraHeading {
  padding: 10px 0;
}

.eupathdb-PathwayGeneraPresets {
  margin: 10px 0;
}

.eupathdb-PathwayGeneraPresetEntry {
  display: inline-block;
  margin: 0 5px;
}

.eupathdb-PathwayGeneraPresetOptions {
  font-size: smaller !important;
}

.wdk-UserComments-Form {
  position: relative;

  input:required {
    box-shadow: none;
  }

  .wdk-UserComments-Form-LoadingOverlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: #3333337d;
    height: 100%;
    z-index: 1;
    width: 100vw;
    margin-left: -2em;
    display: flex;
    justify-content: center;
  }

  .wdk-UserComments-Form-Loading {
    position: absolute;
    top: 45%;
    display: flex;
    flex-direction: column-reverse;
    justify-content: space-between;
    height: 5em;
    font-size: 1.5em;
    background: #f3f3f3e0;
    border-radius: 12px;
    box-shadow: 0 0 4px 1px #00000036;
  }

  .wdk-UserComments-Form-Body {
    form {
      > div:first-child {
        display: grid;
        grid-template-columns: 1fr;
        margin-top: 1em;

        // TODO: Once (if?) we drop IE11 support, we can use
        // "row-gap" instead of applying "margin-bottom"
        // to the direct descendants
        > * {
          margin-bottom: 1em;
        }
      }

      .wdk-UserComments-Form-Errors {
        display: flex;
        color: red;
      }

      > :nth-child(3) {
        display: flex;
        justify-content: center;
      }
    }

    .wdk-UserComments-Form-Group {
      .wdk-UserComments-Form-Group-Header {
        background-color: #88aaca;
        color: #ffffff;
        font-weight: bold;
        padding: 0.25em;
      }

      .wdk-UserComments-Form-Group-Body {
        display: grid;
        grid-template-columns: 17em 1fr;
        margin-top: 0.5em;

        // TODO: Once (if?) we drop IE11 support, we can use
        // "row-gap" instead of applying "margin-bottom"
        // to the direct descendants
        > * {
          margin-bottom: 0.5em;
        }

        > label {
          margin-left: 1em;
        }

        > div > input[type='text'] {
          box-sizing: content-box;
          width: calc(100ch + 4px);
        }

        > div > textarea {
          height: 5em;
          box-sizing: content-box;
          width: calc(100ch + 4px);
        }

        .HelpTrigger {
          margin-left: 0.5em;
        }

        .wdk-LocationField {
          border: 1px solid black;
          box-sizing: content-box;
          width: 100ch;
          display: grid;
          grid-template-columns: 20ch 1fr;
          padding: 0.5em 0.5em 0 0.5em;

          // TODO: Once (if?) we drop IE11 support, we can use
          // "row-gap" instead of applying "margin-bottom"
          // to the direct descendants
          > * {
            margin-bottom: 0.5em;
          }

          input[type='text'] {
            width: calc(100% - 2.5em);
          }
        }

        .wdk-AttachmentsField {
          .wdk-FileInputSubfield {
            border: 1px black solid;
            box-sizing: content-box;
            width: 100ch;
            padding: 0.5em 0.5em 0 0.5em;
            margin-bottom: 0.5em;
            display: grid;
            grid-template-columns: 20ch 1fr 1em;

            // TODO: Once (if?) we drop IE11 support, we can use
            // "row-gap" instead of applying "margin-bottom"
            // to the direct descendants
            > * {
              margin-bottom: 0.5em;
            }

            textarea {
              height: 5em;
              width: 100%;
            }

            &:nth-child(even) {
              background-color: rgb(204, 204, 204);
            }
          }
        }

        .wdk-PubMedIdsField {
          border: 1px solid black;
          box-sizing: content-box;
          width: 100ch;
          padding: 0.5em;

          .wdk-PubMedIdInputField {
            > * {
              margin-bottom: 0.5em;
            }

            > input {
              width: calc(100% - 2.5em);
            }
          }

          .wdk-PubMedIdSearchField {
            margin: 1em 0;
            display: flex;

            > button {
              margin-right: 0.5em;
              width: 29ch;
            }

            > input {
              width: 100%;
            }
          }

          .wdk-PubMedIdPreview {
            border: 1px lightgray solid;
            display: grid;
            grid-template-columns: 1fr;
            padding: 0.5em;

            > a {
              justify-self: right;
            }

            .wdk-PubmedIdEntry {
              padding-bottom: 0.5em;
              border-bottom: 1px rgb(128, 128, 128) solid;
              margin-bottom: 1em;
              display: grid;
              grid-template-columns: 10ch 1fr;

              > * {
                padding: 0.5em;
              }

              > *:nth-child(1),
              > *:nth-child(2) {
                background-color: lightgrey;
              }
            }
          }
        }
      }
    }
  }
}

@font-face {
    font-family: "ebrc-icons";
    src: url("./ebrc-icons.eot?d75f6c69774a372e3afe1cba4e81ce6e?#iefix") format("embedded-opentype"),
url("./ebrc-icons.woff2?d75f6c69774a372e3afe1cba4e81ce6e") format("woff2"),
url("./ebrc-icons.woff?d75f6c69774a372e3afe1cba4e81ce6e") format("woff"),
url("./ebrc-icons.ttf?d75f6c69774a372e3afe1cba4e81ce6e") format("truetype"),
url("./ebrc-icons.svg?d75f6c69774a372e3afe1cba4e81ce6e#ebrc-icons") format("svg");
}

[class^="ebrc-icon-"], [class*=" ebrc-icon-"] {
    display: inline-block;
    position: relative;
    bottom: -0.1em;
    font: normal normal normal 1em/1 ebrc-icons;
    text-transform: none;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.ebrc-icon-discord-logo:before {
    content: "\f101";
}
.ebrc-icon-download:before {
    content: "\f102";
}
.ebrc-icon-edaIcon:before {
    content: "\f103";
}
.ebrc-icon-sampleDetails_dark:before {
    content: "\f104";
}
.ebrc-icon-sampleDetails_light:before {
    content: "\f105";
}
.ebrc-icon-table-download:before {
    content: "\f106";
}
.ebrc-icon-table:before {
    content: "\f107";
}
.ebrc-icon-taxaQuery_dark:before {
    content: "\f108";
}
.ebrc-icon-taxaQuery_light:before {
    content: "\f109";
}
.ebrc-icon-x-logo:before {
    content: "\f10a";
}

.CommonModal {
  &Header {
    background: white;
    button {
      border-radius: 50%;
      font-size: 1.2em;
      height: 2em;
      width: 2em;
    }
    i {
      color: #5e5e5e;
    }
  }

  &Title {
    font-size: 1.7em;
    font-weight: normal;
    margin: auto;
    flex: 10;
    overflow: visible;
  }

  &Content {
    max-height: 80vh;
    padding: 1em 2em;
  }

  &LeftButtons,
  &RightButtons {
    align-self: flex-start;
    color: #8e8e8e;
  }
}

.ResultTableSummaryView {
  position: relative;

  > .MesaComponent {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    // Make selection count appear after action buttons
    .ActionToolbar {
      flex-direction: row-reverse;
    }

    // reduce padding/margin around heading elements
    .HeadingCell {
      button,
      .SortTrigger {
        padding: 1px;
        font-size: 120%;
        margin-left: 0;
      }
      button i.fa-shopping-basket {
        font-size: 110%;
      }
      .HelpTrigger .icon {
        font-size: 130%;
      }
      button i.fa-times-circle {
        font-size: 110%;
      }
    }

    // Force PagniationMenu to come before Toolbar
    .Toolbar + .PaginationMenu {
      order: -1;
      margin-right: auto;
    }

    .TableToolbar-Children {
      display: flex;
      align-items: center;
    }

    // nested .MesaComponent
    > .MesaComponent {
      width: 100%;
      overflow: auto;
    }
  }

  .ResultTableButton + .ResultTableButton {
    margin-left: 1em;
  }

  .ResultTableButton {
    .fa-download {
      color: #069;
    }
    .fa-shopping-basket {
      color: green;
    }
  }

  td.DataCell:empty:after,
  td.DataCell > div:empty:after {
    content: 'N/A';
    opacity: 0.4;
  }

  .ResultTableSummaryViewLoadingOverlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: #3333337d;
    height: 100%;
    z-index: 1;
    width: 100%;
    margin: -1em;
    padding: 1em;
    display: flex;
    justify-content: center;
  }

  .ResultTableSummaryViewLoading {
    position: absolute;
    top: 45%;
    display: flex;
    flex-direction: column-reverse;
    justify-content: space-between;
    height: 5em;
    font-size: 1.5em;
    background: #f3f3f3e0;
    border-radius: 12px;
    box-shadow: 0 0 4px 1px #00000036;
  }

  .MesaComponent {
    th,
    td {
      padding: 4px;
      padding-right: 6px;
      vertical-align: middle;
    }

    // Remove padding from basket table cells
    .BasketCell {
      padding: 0;
      text-align: center;
      width: 4em;
    }
  }

  // Add padding to basket button (increase clickable area)
  .ResultTableBasketIconButton {
    width: 3em;
    padding: 5px;
    background: none;
    border: none;
    outline-offset: -5px;
  }

  .RemoveColumnButton {
    font-size: 1rem;
    color: maroon;
  }
}

.AddColumnsDialogContent {
  flex-direction: column;
  justify-content: space-between;
  padding: 0;

  .btn.ResultTable-AddColumnsButton {
    margin: 1em auto;
  }
}

.ContactUsScreenshots {
  &--ScreenshotPreviews {
    margin-top: 0.5em;
  }

  &--ScreenshotPreview {
    display: flex;
    align-items: center;

    margin-bottom: 1em;

    > * {
      flex: 0 0 auto;
    }

    img {
      width: 27em;
      border: 0.2em solid #aaaaaa;
    }

    button.btn {
      margin-left: 1em;
    }
  }

  &--AddScreenshot.btn {
    margin-bottom: 0;
    margin-left: 0.5em;
  }
}

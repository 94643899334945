.StrategyControls {
  &--Controls {
    display: flex;
  }

  &--Controls > * {
    font-size: 1.5em;
    margin-left: 0.5em;
    margin-right: 0.5em;
  }

  &--Action {
    min-width: 30em;
    min-height: 10em;
    display: flex;
    flex-flow: column;
    justify-content: space-around;
    align-items: center;
    text-align: center;
  }
}

.support-form-base {
  float: left;
  width: 100%;
  //padding: 12px 0;
  position: relative;
  min-width: 900px;
  margin: 1em 3em 1em 3em;

  .support-form-contents {
    //display: flex;
    //flex-direction: column;
    //padding: 1em;

    .support-form-body {
      align-self: center;
      font-size: 120%;

      h4 {
        margin: 1em 0em;
      }

      p {
        font-size: 120%;
      }

      ul {
        list-style: disc outside none;
        margin: 1em 0em;
        padding: 0em 2em;
        line-height: 1.2;
      }

      table {
        //    margin: 2em auto;
      }

      .center {
        text-align: center;
      }

      .instructions {
        text-align: left;
      }

      .field {
        td {
          vertical-align: top;
        }
      }
    }
  }
}

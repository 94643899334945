.HistogramAnalysis {
  $graph-height: 400px;
  $graph-width: 700px;

  &AttrLabel,
  &RecordCountLabel {
    display: flex;
    justify-content: center;
    align-items: baseline;
    font-weight: bold;
  }

  &RecordCountLabel {
    transform: rotate(270deg) translateY(2em);
    transform-origin: bottom left;
    width: $graph-height;
  }

  &Graph {
    width: $graph-width;
    height: $graph-height;
    margin: auto;
  }

  &Summary {
    dl {
      display: flex;
      justify-content: center;
    }

    dd {
      margin-left: 0.5em;
      margin-right: 1em;
    }
  }

  &Controls {
    align-items: center;
    background: #eee;
    display: flex;
    margin: 0.5em 0;
    padding: 1em;

    > * {
      margin: 0 0.5em;
    }
  }

  &Slider {
    flex-grow: 2;
  }
}

.ebrc-SocialMediaLinks {
  display: flex;
  align-items: center;

  font-size: 1.6em;

  button.link {
    display: flex;
    flex-direction: column;
    align-items: center;

    i {
      font-size: 1.2em;
    }

    span {
      font-size: 0.6em;
    }
  }

  > a {
    margin: 0.625em 1.1em;

    color: #6c757d;
  }

  &--AnnouncementsToggle {
    &__hidden {
      visibility: hidden;
    }
  }
}

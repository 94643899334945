body.wdk-ModalOpen {
  overflow: hidden;
}

.wdk-Dialog {
  padding: 0;
  border: 1px solid #999;
  border-radius: 4px;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.4);
  position: absolute;
  background: white;
  box-sizing: content-box;
  min-width: 300px;
  display: flex;
  flex-direction: column;
}

.wdk-Dialog__modal {
  max-height: calc(100vh - 2em);
}

.wdk-Dialog__modal:before {
  content: ' ';
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.3);
}

.wdk-DialogPopupWrapper {
  position: fixed;
  top: 50vh;
  left: 50vw;
  z-index: 1;
  height: 0px;
  width: 0px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.wdk-DialogHeader {
  display: flex;
  align-items: center;
  position: relative;
  font-size: 1.2em;
  font-weight: 700;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  background: #eee;
  padding: 0.6em 0.8em;
  cursor: move;
}

.wdk-DialogHeader button {
  border: none;
  background: none;
}

.wdk-DialogHeader button:hover {
  background: #eee;
}

.wdk-DialogTitle {
  display: flex;
  justify-content: center;
  flex: 5;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  margin-right: auto;
}

.wdk-DialogLeftButtons,
.wdk-DialogRightButtons {
  display: flex;
  flex: 1;
}

.wdk-DialogLeftButtons {
  justify-content: flex-start;
}

.wdk-DialogRightButtons {
  justify-content: flex-end;
}

.wdk-DialogContent {
  display: flex;
  justify-content: center;
  position: relative;
  background: white;
  padding: 0.8em;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  box-sizing: border-box;
  overflow: auto;
  flex: 2;
}

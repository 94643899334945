.CombineStepMenu {
  &--OperatorSelector {
    display: flex;
    justify-content: space-evenly;
    margin: 1em 1em 2em 2em;
  }

  &--OperatorChoice {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 0.5em;

    input {
      margin-right: 0.5em;
      margin-top: 0.5em;
    }

    label {
      display: flex;
      align-items: center;
      padding: 0 0.35em 0.75em 0;
      border-bottom: 0.125rem solid transparent;
    }

    &__selected {
      label {
        border-color: #4f81bd;
      }
    }
  }

  .SearchInputSelector {
    margin: 1em 1em 2em 2em;
  }
}

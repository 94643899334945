.MesaComponent {
  .TruncatedText {
    button.TruncatedText-Toggle {
      margin: 10px auto;
      .icon {
        margin-left: 5px;
      }
    }
  }
}

/*~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
	 = internal dataset question pages, tabs
~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~*/
.wdk-TabContent h1 {
  font-size: 200%;
}

.wdk-TabContent .wdk-QuestionForm__default-width {
  max-width: 72.5rem;
}

/*~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
              = Data Set Record page
~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~*/
.wdk-RecordContainer__DatasetRecordClasses\.DatasetRecordClass
  .wdk-RecordActions
  a[title='Download Data Set'] {
  visibility: hidden;
}

/*~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
           = Sidebar
~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~*/

#menu_lefttop .ui-accordion-header {
  padding: 0;
  margin: 0;
  background: none;
  border: none;
}

#menu_lefttop .ui-accordion-content {
  padding: 6px;
}

/*~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
    Header stuff
~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~*/

header {
  overflow: visible;
  position: relative;
  z-index: 100;
}

/*~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
	 =NEW FOOTER (gene page)
~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~*/
.Footer {
  background-color: #f0f0f0;
}

/*~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
      = Results Table: Boolean and Leaf transcript Filters
~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~*/
div.MatchedTranscriptsFilter {
  font-size: 110%;
  margin-right: 2em;
  font-weight: 500;
  color: darkred;
}

/* BOOLEAN, combined?  */

.gene-boolean-filter,
.gene-leaf-filter {
  display: none;
  margin-bottom: 1em;
}
.gene-boolean-filter a.gene-boolean-filter-controls-toggle,
.gene-leaf-filter a.gene-leaf-filter-controls-toggle {
  padding-left: 0.5em;
}
.gene-boolean-filter-controls,
.gene-leaf-filter-controls {
  display: none;
  text-align: center;
}
.gene-boolean-filter table,
.gene-leaf-filter table {
  border-collapse: separate;
  color: black;
}
.gene-boolean-filter-summary,
.gene-leaf-filter-summary {
  display: inline-block;
}
.gene-boolean-filter-controls p,
.gene-leaf-filter-controls p {
  text-align: center;
}
.gene-boolean-filter-controls button:disabled,
.gene-leaf-filter-controls button:disabled {
  color: grey;
}
table#booleanFilter td#prompt,
table#leafFilter td#prompt {
  font-weight: bold;
}
table#booleanFilter table td {
  padding: 0 8px;
}
table#booleanFilter table img {
  width: 80px;
}
table#leafFilter table img {
  width: 20px; /* same size as in attribute column in site/wdkAttribute.tag */
}
table#booleanFilter table img,
table#leafFilter table img {
  vertical-align: text-bottom;
  margin-left: 10px;
}
/*~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
      = results table view filter (representative transcript)
~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~*/

div#oneTr-filter {
  font-family: Verdana;
  font-weight: bold;
  padding-left: 0.5em;
  margin-top: 1em;
}
div#oneTr-filter span#prompt,
div#oneTr-filter span span {
  color: darkred;
  padding-right: 1em;
}

/* ========================================================================= */
/* Gene  page   - Transcriptomics, coverage graph                            */
/* ========================================================================= */
div.eupathdb-GbrowseContextContent {
  margin-right: 2em;
}

/* ---------------------------------------------------------------
                    jQuery UI Override
    -------------------------------------------------------------*/

.ui-widget input,
.ui-widget select,
.ui-widget textarea,
.ui-widget button {
  font-family: inherit;
}

.api-PlasmoAp {
  h2 {
    font-size: 1.6em;
  }

  p {
    font-size: 1.3em;
  }

  &--FormContainer {
    form {
      display: inline-grid;
      grid-gap: 0.75em;
      justify-items: center;

      background-color: #bdc0fa;

      margin-bottom: 2em;
      padding: 1em;

      button {
        font-size: 1em;

        margin: 0 0.5em;
      }
    }
  }
}

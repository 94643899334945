$red: #dd314e;
$textColor: rgb(56, 76, 75);
$paleBlue: rgb(198, 223, 227);

@import './Cards.scss';

.wdk-Showcase {
  width: 100%;
  margin: 20px 0 50px;

  button.ViewAll {
    border: none;
    color: white;
    margin: 10px;
    font-weight: 700;
    font-size: 1.1em;
    line-height: 1.1em;
    padding: 8px 16px;
    border-radius: 20px;
    background: $red;
    transition: all 0.4s;
    -webkit-transition: all 0.4s;
    text-transform: uppercase;
  }

  button.ViewAll:hover {
    transform: translateY(-4px);
    box-shadow: 0 4px 2px rgba(0, 0, 0, 0.1);
    text-shadow: 0 0 3px rgba(255, 255, 255, 0.3);
  }

  .row {
    align-items: center;
    justify-content: space-between;
    flex: auto;

    &.wdk-Showcase-HeadingRow {
      margin-top: -20px;
      flex: 0 0 auto;

      .box {
        padding: 10px;
      }
      p {
        font-size: 0.8rem;
        max-width: 800px;
        margin: 5px 10px 0;
      }
      h2 {
        margin: 0 10px;
        padding: 0;
        font-size: 2.3rem;
        font-weight: bold;
      }
    }
  }

  .wdk-ShowcaseFilter {
    justify-content: flex-end;
    align-items: center;
    flex: 0 0 auto;
    &-Item {
      display: flex;
      flex-direction: row;
      flex: 0 0 auto;
      padding: 5px 15px;
      align-items: center;
      transition: opacity 0.5s;
      &.inactive {
        opacity: 0.6;
      }
      label {
        flex: 1;
        display: flex;
        flex-direction: row;
        align-items: center;
        font-size: 1.3rem;
        text-transform: uppercase;
        font-weight: 600;
        color: #384c4b;
        .CategoryIcon {
          transform: scale(0.8);
        }
      }
    }
  }

  .wdk-Showcase-HeadingControls {
    display: flex;
    flex: 0 0 auto;
    align-items: center;
    justify-content: flex-end;
  }
}

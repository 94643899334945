.wdk-CheckboxList {
  &Item label {
    display: flex;
    align-items: center;
    margin: 0.5ex 0;
    > input {
      margin-right: 0.5ch;
    }
  }
  &Links {
    font-size: 0.9em;
    margin: 0.5em;
    text-align: center;
  }
}

.ebrc-SocialMediaIcon {
  width: 1em;

  &__wide {
    width: 1.125em;
  }

  .fa-envelope-o {
    margin-bottom: 0.25em;
  }
}

.wdk-Modal-Wrapper {
  width: 100vw;
  height: 100vh;
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  top: 0;
  left: 0;
  pointer-events: none;
  opacity: 0;
  z-index: 3;
  background-color: rgba(0, 0, 0, 0.4);
  transition: all 0.5s;
  padding: 5em 0;

  &.wdk-Modal-Wrapper--Active {
    pointer-events: all;
    opacity: 1;
    overflow: auto;
  }
}

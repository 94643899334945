.GenesByOrthologPattern {
  &ProfileParameter {
    &TreeWrapper {
      max-width: 650px;
      position: relative;

      [class$='-CheckboxTree'] {
        > [class$='-CheckboxTreeList']
          > [class$='-CheckboxTreeItem']
          > [class$='-CheckboxTreeNodeWrapper']
          > i {
          visibility: hidden;
        }

        .GenesByOrthologPatternConstraintIcon {
          cursor: pointer;
        }
      }

      [class$='-CheckboxTreeList'] {
        .wdk-CheckboxTreeToggle {
          &__collapsed {
            margin-left: 0.1em;
          }
        }
      }

      [class$='-CheckboxTreeNodeContent'] {
        margin-left: 1em;
      }

      [class$='-CheckboxTreeLinks'] {
        position: absolute;
        top: 1.1em;
        left: 9.5em;
        z-index: 1;
        margin: 0 0 0 1rem;

        > div {
          text-align: left;
        }
      }
    }
  }

  &ConstraintIconLegend {
    font-style: italic;

    .GenesByOrthologPatternConstraintIcon {
      margin-left: 0.25em;
    }
  }

  &ConstraintIcon {
    &__free {
      content: url('/a/images/dc.gif');
    }

    &__include {
      content: url('/a/images/yes.gif');
    }

    &__exclude {
      content: url('/a/images/no.gif');
    }

    &__mixed {
      content: url('/a/images/unk.gif');
    }
  }

  &ProfileNodeDisplay {
    margin: 0 0.25em;

    &__root-node {
      font-weight: bold;
    }

    &__interior-node {
      font-style: italic;
    }
  }
}

@import './breakpoints.scss';

$collapsed-header-height: 5.5em;

$branding-height: 7em;
$small-branding-scale-factor: 0.57142857142em;

@mixin collapsed-header-styling {
  display: grid;
  position: fixed;
  height: auto;
  padding: 0.5em 0.75em;
  grid-template-areas:
    'site-branding searchbar social login'
    'site-branding menubar social login';

  box-shadow: 0 2px 4px #00000066;

  .ebrc-HeaderBrandingContainer {
    font-size: $small-branding-scale-factor;
  }

  .ebrc-HeaderSearchBar {
    input,
    form {
      line-height: normal;
    }

    button {
      line-height: normal;
      padding: 2px 6px;
    }
  }

  .ebrc-HeaderBrandingContainer {
    align-self: center;
  }

  .ebrc-HeaderProjectBranding {
    display: none;
  }

  .UserMenu {
    align-self: flex-end;
  }

  .ebrc-HeaderMenuBar
    > .ebrc-HeaderMenuItemGroup
    > .ebrc-HeaderMenuItemContent {
    row-gap: 0.5em;
    padding: 0 0.5em;

    @media screen and (max-width: $hamburger-width) {
      padding: 0.5em;
    }
  }
}

@mixin collapsed-header-page-layout {
  @media screen and (min-width: $hamburger-width + 1) {
    grid-template-rows: $collapsed-header-height auto 1fr auto;
  }

  .vpdb-Header {
    height: auto;

    .ebrc-HeaderBrandingContainer {
      margin-top: 0;
    }

    @media screen and (min-width: $hamburger-width + 1) {
      @include collapsed-header-styling;
    }
  }

  .vpdb-Footer {
    display: none;
  }
}

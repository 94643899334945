.PublicStrategies {
  margin-top: 1em;
  &--Info {
    display: flex;
    align-items: center;

    .wdk-Icon {
      font-size: 1.5em;
      margin-right: 0.25em;
    }
  }

  &--PriorityCheckbox {
    display: flex;
    align-items: center;

    input {
      margin-right: 0.25em;
    }
  }

  .MesaComponent {
    .TableToolbar-Children {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;

      padding: 1em 0;

      white-space: nowrap;

      .PublicStrategies--SearchGroup {
        display: flex;
        align-items: center;

        .PublicStrategies--SearchTitle {
          margin-right: 1em;
          padding: unset;
        }

        .wdk-RealTimeSearchBox {
          width: 33%;
        }
      }
    }

    .MesaComponent {
      width: 100%;

      td {
        padding: 0.5em;
      }

      .PublicStrategies--ExampleRow {
        font-weight: bold;
      }
    }
  }
}

.vpdb-Main {
  grid-area: content;

  margin: 1rem 0;
  padding: 0 1rem 0 1.25rem;
  position: relative;
  z-index: 1;

  > hr {
    margin: 1.5rem 0 0.5rem 0;
  }
}

.MesaComponent {
  .PaginationMenu-DisallowClick {
    pointer-events: none;
    opacity: 0.5;
  }

  .PaginationMenu {
    padding: 10px 0;
    text-align: center;
    color: inherit;
    align-items: center;
    display: flex;

    .Pagination-Nav {
      flex: 0 0 auto;
      padding: 0 10px;

      > * {
        color: inherit;
        background: none;
        display: inline-block;
        padding: 5px;
        margin: 0;
        border-radius: 3px;
        transition: all 0.4s;
        font-weight: 600;
        border: 1px solid grey;
        min-width: 30px;
        height: 30px;
        line-height: 1.1em;

        &.ellipsis {
          border-color: transparent;
          margin: 0 5px;
        }

        &.active {
          opacity: 1;
          border: 1px solid grey;
          background-color: grey;
          color: white;
        }
        &.inactive {
          opacity: 0.5;
          border: 1px solid silver;
        }
        opacity: 0.5;
        &:hover {
          opacity: 1;
        }
      }
      button i.icon {
        font-size: 150%;
      }
    }

    .Pagination-Editor,
    .Pagination-Spacer {
      flex: 1;
      padding-left: 20px;
      text-align: right;
      /*  font-size: 0.8em; */
      white-space: nowrap;
    }
  }
}

.ebrc-ProjectLink {
  .AmoebaDB {
    content: url('~@veupathdb/web-common/images/VEuPathDB/icons-footer/amoebaDB.png');
  }

  .ClinEpiDB {
    content: url('~@veupathdb/web-common/images/VEuPathDB/icons-footer/clinepidb.png');
  }

  .CryptoDB {
    content: url('~@veupathdb/web-common/images/VEuPathDB/icons-footer/cryptoDB.png');
  }

  .FungiDB {
    content: url('~@veupathdb/web-common/images/VEuPathDB/icons-footer/fungiDB.png');
  }

  .GiardiaDB {
    content: url('~@veupathdb/web-common/images/VEuPathDB/icons-footer/giardiaDB.png');
  }

  .HostDB {
    content: url('~@veupathdb/web-common/images/VEuPathDB/icons-footer/hostDB.png');
  }

  .MicrobiomeDB {
    content: url('~@veupathdb/web-common/images/VEuPathDB/icons-footer/microbiomedb.png');
  }

  .MicrosporidiaDB {
    content: url('~@veupathdb/web-common/images/VEuPathDB/icons-footer/microsporidiaDB.png');
  }

  .PiroplasmaDB {
    content: url('~@veupathdb/web-common/images/VEuPathDB/icons-footer/piroplasmaDB.png');
  }

  .PlasmoDB {
    content: url('~@veupathdb/web-common/images/VEuPathDB/icons-footer/plasmoDB.png');
  }

  .ToxoDB {
    content: url('~@veupathdb/web-common/images/VEuPathDB/icons-footer/toxoDB.png');
  }

  .TrichDB {
    content: url('~@veupathdb/web-common/images/VEuPathDB/icons-footer/trichDB.png');
  }

  .TriTrypDB {
    content: url('~@veupathdb/web-common/images/VEuPathDB/icons-footer/tritrypDB.png');
  }

  .OrthoMCL {
    content: url('~@veupathdb/web-common/images/VEuPathDB/icons-footer/orthomclDB.png');
  }

  .VectorBase {
    content: url('~@veupathdb/web-common/images/VEuPathDB/icons-footer/vectorbase.png');
  }

  .VEuPathDB {
    content: url('~@veupathdb/web-common/images/VEuPathDB/icons-footer/VEuPathDB.png');
  }
}

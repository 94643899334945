@import './breakpoints.scss';

body.vpdb-Body .vpdb-RootContainer {
  .vpdb-NewsPane {
    overflow: auto; // fixes https://redmine.apidb.org/issues/41643
    grid-area: side;

    border-left: 0.0625rem solid;

    &__news-collapsed {
      border-left-style: hidden;
    }

    .wdk-Showcase-HeadingRow {
      display: none;
    }

    @media screen and (max-width: $tablet-width) {
      border-left: none;

      .wdk-Showcase-HeadingRow {
        display: flex;
      }
    }
  }

  .wdk-Showcase {
    margin: 0;
  }
  .News-Section .News .NewsList {
    max-height: 145px;
    border-color: rgba(15, 70, 100, 0.12);
    border-style: solid;
    border-width: 1px 0;
  }
  .News-Section .News .NewsList .NewsTeaser {
    display: none;
  }
  .News-Section .AllNewsLink {
    font-size: 110%;
  }
}

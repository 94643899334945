.ConvertStepMenu {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  max-height: 70vh;
  font-size: 1.2em;

  &--OperatorSelector {
    display: grid;
    grid-template-columns: 1fr;

    row-gap: 1em;

    width: max-content;
    margin: 1em auto 1em auto;
  }

  &--Content {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  h2 {
    margin: 0 1em 1em 0;
    font-size: 1.5em;
  }

  button {
    padding: 0.5em;
    background: #f1f1f1;
    color: black;
    border-color: black;
    border-width: 0.0625rem 0.0625rem 0.125rem 0.0625rem;
    border-radius: 5px;
  }
}

.Downloads {
  h1 {
    padding: 0;
  }

  &-Instructions {
    font-size: 1.1em;
    margin: 1em 0 2em 0;
    color: #444;
    &.portal {
      margin-bottom: 1em;
    }
  }

  &-Instructions li {
    padding-bottom: 0.3em;
  }

  &-Filter-Container {
    .wdk-QuestionFormParameterList {
      width: 60vw;
      margin: auto;
      min-width: 800px;
    }

    .wdk-QuestionFormParameterHeading h2 {
      font-size: 1.3em;
    }

    .OrganismParam {
      max-width: 47em;
    }

    .filter-param {
      outline: 2px solid #acacac;
      // margin: 2em auto 3em;
      // width: 60vw;
      border-radius: 8px;
      // height: 25em;

      h3 {
        padding: 0.25em 0;
      }
      .membership-actions {
        display: none;
      }
      .membership-filter {
        margin-top: 1.5em;
      }
      .DataTable--Sticky {
        height: 14em;
      }
      .field-list > * {
        &:nth-child(1),
        &:nth-child(2) {
          display: none;
        }
        &:nth-child(3) {
          margin-top: 4em !important;
        }
      }

      .HeadingRow .HeadingCell,
      .DataRow .DataCell {
        &:nth-child(3),
        &:nth-child(4),
        &:nth-child(5),
        &:nth-child(6) {
          display: none;
        }
      }

      .filters,
      .filters-server-side {
        min-height: auto;
      }
    }
  }

  .ResultTableSummaryView > .MesaComponent {
    .ActionToolbar {
      flex-direction: row-reverse;
      order: -1;
      margin-right: auto;
    }

    .TableToolbar {
      .TableToolbar-Info {
        font-size: 100%;
      }
      order: -3;
    }

    .TableToolbar-Children {
      display: none;
    }

    .Toolbar + .PaginationMenu {
      order: -2;
      margin-right: auto;
      margin-right: 3em;
    }
  }
}

/* Vars -=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-*/
$gray: #ccc;
$white: #fdfdfd;
$lightgray: #eaeaea;
$mediumgray: #717171;
$darkgray: #3e3e3e;
$blue: #17b;
$red: #cb2b39;
$green: #558e0c;
$gold: #daaa4a;
$orange: #ee6f13;
$darkblue: #04255e;

.gray-text {
  color: $gray;
}
.gray-bg {
  background-color: $gray;
}
.gray-border {
  border-color: $gray;
}

.white-text {
  color: $white;
}
.white-bg {
  background-color: $white;
}
.white-border {
  border-color: $white;
}

.lightgray-text {
  color: $lightgray;
}
.lightgray-bg {
  background-color: $lightgray;
}
.lightgray-border {
  border-color: $lightgray;
}

.mediumgray-text {
  color: $mediumgray;
}
.mediumgray-bg {
  background-color: $mediumgray;
}
.mediumgray-border {
  border-color: $mediumgray;
}

.darkgray-text {
  color: $darkgray;
}
.darkgray-bg {
  background-color: $darkgray;
}
.darkgray-border {
  border-color: $darkgray;
}

.blue-text {
  color: $blue;
}
.blue-bg {
  background-color: $blue;
}
.blue-border {
  border-color: $blue;
}

.red-text {
  color: $red;
}
.red-bg {
  background-color: $red;
}
.red-border {
  border-color: $red;
}

.green-text {
  color: $green;
}
.green-bg {
  background-color: $green;
}
.green-border {
  border-color: $green;
}

.gold-text {
  color: $gold;
}
.gold-bg {
  background-color: $gold;
}
.gold-border {
  border-color: $gold;
}

.orange-text {
  color: $orange;
}
.orange-bg {
  background-color: $orange;
}
.orange-border {
  border-color: $orange;
}

.darkblue-text {
  color: $darkblue;
}
.darkblue-bg {
  background-color: $darkblue;
}
.darkblue-border {
  border-color: $darkblue;
}

$news-width: 300px;
$news-height: 280px;
$news-border-color: #0f597099;
$news-border-width: 2px;

.News-Section {
  margin-left: 0.75em;
  margin-right: 1em;
  flex: $news-width 0 0;

  .News {
    margin-bottom: 1em;
    padding-bottom: 1em;
    border-bottom: $news-border-width solid $news-border-color;
    width: 100%;
    .NewsList {
      overflow-y: auto;
      max-height: $news-height;
    }
  }

  .NewsLoadError {
    color: darkred;
    padding: 0.6rem;
  }

  .wdk-Showcase-HeadingControls {
    padding: 0;
  }

  .wdk-Showcase-ContentRow {
    padding: 0.5rem 0;
    background: white;
    border: $news-border-width solid $news-border-color;
    a {
      color: #0f5970;
    }
  }

  .AllNewsLink,
  .NewsEntry,
  a.twitter-timeline {
    padding: 0 0.6rem;
  }

  .NewsEntry {
    // background: rgba(15, 89, 112, 0.11);
    margin-bottom: 0.4rem;

    &:hover,
    &:focus,
    &:active {
      background: rgba(15, 89, 112, 0.05);
    }
    .NewsHeadline {
      font-weight: 500;
      padding: 0.2rem 0;
      a {
        text-decoration: underline;
      }
    }

    .NewsDate {
      font-weight: 400;
      text-transform: uppercase;
    }

    .NewsTeaser {
      padding: 0.4rem 0;
    }
  }

  .AllNewsLink {
    display: block;
    padding-top: 1.2rem;
    padding-bottom: 0.2rem;
  }

  .TwitterTimelineContainer {
    p {
      margin: 0.25em 0;
    }
  }
}

.AddStepPanel {
  background: white;

  &--Container {
    min-width: 1000px;
    max-width: 95vw;
  }

  &--Header {
    display: flex;
    justify-content: space-between;

    i {
      color: #9e9e9e;
    }
  }

  &--MenuContainer {
    display: grid;
    grid-template-columns: auto 1em 60em;
    align-items: flex-start;
    grid-gap: 1em;
    grid-template-areas: 'menu-selector . selected-menu';
  }

  &--MenuSelector {
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 1em;
    margin-bottom: 1em;
    grid-area: menu-selector;
  }

  &--SelectedMenuContainer {
    margin: 0 1em 1em 1em;
    height: 45em;
    grid-area: selected-menu;

    strong {
      font-size: 1.2em;
      font-weight: 500;
    }

    .MenuChoicesContainer {
      counter-reset: panel;

      display: grid;
      grid-template-columns: 1fr;
      grid-gap: 1em;

      .MenuChoice::before {
        counter-increment: panel;
        content: counter(panel);
        background: #777777;
        border-radius: 1em;
        height: 1.5em;
        width: 1.5em;
        display: inline-flex;
        justify-content: center;
        align-items: center;
        margin-right: 0.5em;
        color: white;
      }
    }
  }
}

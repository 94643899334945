.wdk-TabsContainer.wdk-MutuallyExclusiveParamsGroupContainer {
  width: 100%;
  margin-top: 1em;

  .wdk-Tabs {
    & {
      display: flex;
      width: 100%;
      border-bottom: 1px solid #ccc;
    }

    .wdk-Tab {
      cursor: pointer;
      background: #e6e6e6;
      margin: 0 0.5em -1px 0;
      border: 1px solid #ccc;
      padding: 2px 6px;

      &__active {
        background-color: #fafafa;
        border-bottom: unset;
        border-top-right-radius: 4px;
        border-top-left-radius: 4px;
      }

      &:hover,
      &:focus,
      &:active {
        background: #c6c6c6;
      }
    }
  }

  .wdk-TabContent {
    position: relative;
    padding: 1em;
    border-top: none;
    background-color: #fafafa;
  }
}

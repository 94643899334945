/* qTip2 v2.2.0 basic css3 | qtip2.com | Licensed MIT, GPL | Wed Mar 19 2014 18:12:14 */
.qtip {
  position: absolute;
  left: -28000px;
  top: -28000px;
  display: none;
  max-width: 280px;
  min-width: 50px;
  font-size: 10.5px;
  line-height: 12px;
  direction: ltr;
  box-shadow: none;
  padding: 0;
}
.qtip-content {
  position: relative;
  padding: 5px 9px;
  overflow: hidden;
  text-align: left;
  word-wrap: break-word;
}
.qtip-titlebar {
  position: relative;
  padding: 5px 35px 5px 10px;
  overflow: hidden;
  border-width: 0 0 1px;
  font-weight: 700;
}
.qtip-titlebar + .qtip-content {
  border-top-width: 0 !important;
}
.qtip-close {
  position: absolute;
  right: -9px;
  top: -9px;
  cursor: pointer;
  outline: medium none;
  border-width: 1px;
  border-style: solid;
  border-color: transparent;
}
.qtip-titlebar .qtip-close {
  right: 4px;
  top: 50%;
  margin-top: -9px;
}
* html .qtip-titlebar .qtip-close {
  top: 16px;
}
.qtip-titlebar .ui-icon,
.qtip-icon .ui-icon {
  display: block;
  text-indent: -1000em;
  direction: ltr;
}
.qtip-icon,
.qtip-icon .ui-icon {
  -moz-border-radius: 3px;
  -webkit-border-radius: 3px;
  border-radius: 3px;
  text-decoration: none;
}
.qtip-icon .ui-icon {
  width: 18px;
  height: 14px;
  line-height: 14px;
  text-align: center;
  text-indent: 0;
  font: 400 bold 10px/13px Tahoma, sans-serif;
  color: inherit;
  background: transparent none no-repeat -100em -100em;
}
.qtip-focus {
}
.qtip-hover {
}
.qtip-default {
  border-width: 1px;
  border-style: solid;
  border-color: #f1d031;
  background-color: #ffffa3;
  color: #555;
}
.qtip-default .qtip-titlebar {
  background-color: #ffef93;
}
.qtip-default .qtip-icon {
  border-color: #ccc;
  background: #f1f1f1;
  color: #777;
}
.qtip-default .qtip-titlebar .qtip-close {
  border-color: #aaa;
  color: #111;
}
.qtip-light {
  background-color: #fff;
  border-color: #e2e2e2;
  color: #454545;
}
.qtip-light .qtip-titlebar {
  background-color: #f1f1f1;
}
.qtip-dark {
  background-color: #505050;
  border-color: #303030;
  color: #f3f3f3;
}
.qtip-dark .qtip-titlebar {
  background-color: #404040;
}
.qtip-dark .qtip-icon {
  border-color: #444;
}
.qtip-dark .qtip-titlebar .ui-state-hover {
  border-color: #303030;
}
.qtip-cream {
  background-color: #fbf7aa;
  border-color: #f9e98e;
  color: #a27d35;
}
.qtip-cream .qtip-titlebar {
  background-color: #f0de7d;
}
.qtip-cream .qtip-close .qtip-icon {
  background-position: -82px 0;
}
.qtip-red {
  background-color: #f78b83;
  border-color: #d95252;
  color: #912323;
}
.qtip-red .qtip-titlebar {
  background-color: #f06d65;
}
.qtip-red .qtip-close .qtip-icon {
  background-position: -102px 0;
}
.qtip-red .qtip-icon {
  border-color: #d95252;
}
.qtip-red .qtip-titlebar .ui-state-hover {
  border-color: #d95252;
}
.qtip-green {
  background-color: #caed9e;
  border-color: #90d93f;
  color: #3f6219;
}
.qtip-green .qtip-titlebar {
  background-color: #b0de78;
}
.qtip-green .qtip-close .qtip-icon {
  background-position: -42px 0;
}
.qtip-blue {
  background-color: #e5f6fe;
  border-color: #add9ed;
  color: #5e99bd;
}
.qtip-blue .qtip-titlebar {
  background-color: #d0e9f5;
}
.qtip-blue .qtip-close .qtip-icon {
  background-position: -2px 0;
}
.qtip-shadow {
  -webkit-box-shadow: 1px 1px 3px 1px rgba(0, 0, 0, 0.15);
  -moz-box-shadow: 1px 1px 3px 1px rgba(0, 0, 0, 0.15);
  box-shadow: 1px 1px 3px 1px rgba(0, 0, 0, 0.15);
}
.qtip-rounded,
.qtip-tipsy,
.qtip-bootstrap {
  -moz-border-radius: 5px;
  -webkit-border-radius: 5px;
  border-radius: 5px;
}
.qtip-rounded .qtip-titlebar {
  -moz-border-radius: 4px 4px 0 0;
  -webkit-border-radius: 4px 4px 0 0;
  border-radius: 4px 4px 0 0;
}
.qtip-youtube {
  -moz-border-radius: 2px;
  -webkit-border-radius: 2px;
  border-radius: 2px;
  -webkit-box-shadow: 0 0 3px #333;
  -moz-box-shadow: 0 0 3px #333;
  box-shadow: 0 0 3px #333;
  color: #fff;
  border-width: 0;
  background: #4a4a4a;
  background-image: -webkit-gradient(
    linear,
    left top,
    left bottom,
    color-stop(0, #4a4a4a),
    color-stop(100%, #000)
  );
  background-image: -webkit-linear-gradient(top, #4a4a4a 0, #000 100%);
  background-image: -moz-linear-gradient(top, #4a4a4a 0, #000 100%);
  background-image: -ms-linear-gradient(top, #4a4a4a 0, #000 100%);
  background-image: -o-linear-gradient(top, #4a4a4a 0, #000 100%);
}
.qtip-youtube .qtip-titlebar {
  background-color: #4a4a4a;
  background-color: rgba(0, 0, 0, 0);
}
.qtip-youtube .qtip-content {
  padding: 0.75em;
  font: 12px arial, sans-serif;
  filter: progid:DXImageTransform.Microsoft.Gradient(GradientType=0, StartColorStr=#4a4a4a, EndColorStr=#000000);
  -ms-filter: 'progid:DXImageTransform.Microsoft.Gradient(GradientType=0, StartColorStr=#4a4a4a, EndColorStr=#000000);';
}
.qtip-youtube .qtip-icon {
  border-color: #222;
}
.qtip-youtube .qtip-titlebar .ui-state-hover {
  border-color: #303030;
}
.qtip-jtools {
  background: #232323;
  background: rgba(0, 0, 0, 0.7);
  background-image: -webkit-gradient(
    linear,
    left top,
    left bottom,
    from(#717171),
    to(#232323)
  );
  background-image: -moz-linear-gradient(top, #717171, #232323);
  background-image: -webkit-linear-gradient(top, #717171, #232323);
  background-image: -ms-linear-gradient(top, #717171, #232323);
  background-image: -o-linear-gradient(top, #717171, #232323);
  border: 2px solid #ddd;
  border: 2px solid rgba(241, 241, 241, 1);
  -moz-border-radius: 2px;
  -webkit-border-radius: 2px;
  border-radius: 2px;
  -webkit-box-shadow: 0 0 12px #333;
  -moz-box-shadow: 0 0 12px #333;
  box-shadow: 0 0 12px #333;
}
.qtip-jtools .qtip-titlebar {
  background-color: transparent;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr=#717171, endColorstr=#4A4A4A);
  -ms-filter: 'progid:DXImageTransform.Microsoft.gradient(startColorstr=#717171, endColorstr=#4A4A4A)';
}
.qtip-jtools .qtip-content {
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr=#4A4A4A, endColorstr=#232323);
  -ms-filter: 'progid:DXImageTransform.Microsoft.gradient(startColorstr=#4A4A4A, endColorstr=#232323)';
}
.qtip-jtools .qtip-titlebar,
.qtip-jtools .qtip-content {
  background: transparent;
  color: #fff;
  border: 0 dashed transparent;
}
.qtip-jtools .qtip-icon {
  border-color: #555;
}
.qtip-jtools .qtip-titlebar .ui-state-hover {
  border-color: #333;
}
.qtip-cluetip {
  -webkit-box-shadow: 4px 4px 5px rgba(0, 0, 0, 0.4);
  -moz-box-shadow: 4px 4px 5px rgba(0, 0, 0, 0.4);
  box-shadow: 4px 4px 5px rgba(0, 0, 0, 0.4);
  background-color: #d9d9c2;
  color: #111;
  border: 0 dashed transparent;
}
.qtip-cluetip .qtip-titlebar {
  background-color: #87876a;
  color: #fff;
  border: 0 dashed transparent;
}
.qtip-cluetip .qtip-icon {
  border-color: #808064;
}
.qtip-cluetip .qtip-titlebar .ui-state-hover {
  border-color: #696952;
  color: #696952;
}
.qtip-tipsy {
  background: #000;
  background: rgba(0, 0, 0, 0.87);
  color: #fff;
  border: 0 solid transparent;
  font-size: 11px;
  font-family: 'Lucida Grande', sans-serif;
  font-weight: 700;
  line-height: 16px;
  text-shadow: 0 1px #000;
}
.qtip-tipsy .qtip-titlebar {
  padding: 6px 35px 0 10px;
  background-color: transparent;
}
.qtip-tipsy .qtip-content {
  padding: 6px 10px;
}
.qtip-tipsy .qtip-icon {
  border-color: #222;
  text-shadow: none;
}
.qtip-tipsy .qtip-titlebar .ui-state-hover {
  border-color: #303030;
}
.qtip-tipped {
  border: 3px solid #959fa9;
  -moz-border-radius: 3px;
  -webkit-border-radius: 3px;
  border-radius: 3px;
  background-color: #f9f9f9;
  color: #454545;
  font-weight: 400;
  font-family: serif;
}
.qtip-tipped .qtip-titlebar {
  border-bottom-width: 0;
  color: #fff;
  background: #3a79b8;
  background-image: -webkit-gradient(
    linear,
    left top,
    left bottom,
    from(#3a79b8),
    to(#2e629d)
  );
  background-image: -webkit-linear-gradient(top, #3a79b8, #2e629d);
  background-image: -moz-linear-gradient(top, #3a79b8, #2e629d);
  background-image: -ms-linear-gradient(top, #3a79b8, #2e629d);
  background-image: -o-linear-gradient(top, #3a79b8, #2e629d);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr=#3A79B8, endColorstr=#2E629D);
  -ms-filter: 'progid:DXImageTransform.Microsoft.gradient(startColorstr=#3A79B8, endColorstr=#2E629D)';
}
.qtip-tipped .qtip-icon {
  border: 2px solid #285589;
  background: #285589;
}
.qtip-tipped .qtip-icon .ui-icon {
  background-color: #fbfbfb;
  color: #555;
}
.qtip-bootstrap {
  font-size: 14px;
  line-height: 20px;
  color: #333;
  padding: 1px;
  background-color: #fff;
  border: 1px solid #ccc;
  border: 1px solid rgba(0, 0, 0, 0.2);
  -webkit-border-radius: 6px;
  -moz-border-radius: 6px;
  border-radius: 6px;
  -webkit-box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
  -moz-box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
  -webkit-background-clip: padding-box;
  -moz-background-clip: padding;
  background-clip: padding-box;
}
.qtip-bootstrap .qtip-titlebar {
  padding: 8px 14px;
  margin: 0;
  font-size: 14px;
  font-weight: 400;
  line-height: 18px;
  background-color: #f7f7f7;
  border-bottom: 1px solid #ebebeb;
  -webkit-border-radius: 5px 5px 0 0;
  -moz-border-radius: 5px 5px 0 0;
  border-radius: 5px 5px 0 0;
}
.qtip-bootstrap .qtip-titlebar .qtip-close {
  right: 11px;
  top: 45%;
  border-style: none;
}
.qtip-bootstrap .qtip-content {
  padding: 9px 14px;
}
.qtip-bootstrap .qtip-icon {
  background: transparent;
}
.qtip-bootstrap .qtip-icon .ui-icon {
  width: auto;
  height: auto;
  float: right;
  font-size: 20px;
  font-weight: 700;
  line-height: 18px;
  color: #000;
  text-shadow: 0 1px 0 #fff;
  opacity: 0.2;
  filter: alpha(opacity=20);
}
.qtip-bootstrap .qtip-icon .ui-icon:hover {
  color: #000;
  text-decoration: none;
  cursor: pointer;
  opacity: 0.4;
  filter: alpha(opacity=40);
}
.qtip:not(.ie9haxors) div.qtip-content,
.qtip:not(.ie9haxors) div.qtip-titlebar {
  filter: none;
  -ms-filter: none;
}
.qtip .qtip-tip {
  margin: 0 auto;
  overflow: hidden;
  z-index: 10;
}
x:-o-prefocus,
.qtip .qtip-tip {
  visibility: hidden;
}
.qtip .qtip-tip,
.qtip .qtip-tip .qtip-vml,
.qtip .qtip-tip canvas {
  position: absolute;
  color: #123456;
  background: transparent;
  border: 0 dashed transparent;
}
.qtip .qtip-tip canvas {
  top: 0;
  left: 0;
}
.qtip .qtip-tip .qtip-vml {
  behavior: url(#default#VML);
  display: inline-block;
  visibility: visible;
}

.BasketPane {
  &--Header {
    display: flex;
    align-items: center;
  }
  &--Notes {
    margin-left: auto;
    text-align: right;
    font-size: 0.9em;
  }
}

.SearchInputSelector {
  &--CheckboxHeader {
    padding: 0.5em 0;
    text-align: center;
  }

  &--CheckboxContainer {
    padding: 0 0.5em 0.5em 0.5em;
    min-width: 20em;
  }

  .wdk-CategoriesCheckboxTree {
    position: relative;
    border: 1px solid grey;
    padding: 0 0.5em;
    background-color: #f0f0f0;
    border-radius: 1px;
    text-align: left;

    .wdk-CheckboxTreeLinks {
      margin-left: unset;
    }
  }

  .wdk-CheckboxTreeItem__leaf {
    color: #aaa;
    line-height: 1.1em;
    padding: 1px;
    margin-left: 1.5em;

    .wdk-CheckboxTreeNodeContent {
      margin: unset;
    }

    i {
      margin-right: 0.25em;
    }
  }

  .wdk-TabsContainer {
    .wdk-Tabs {
      justify-content: space-evenly;
      padding-bottom: 1em;

      border-bottom-color: #c0c0c0;
    }

    .wdk-Tab {
      position: relative;
      border: unset;
      cursor: default;

      input {
        margin-right: 0.5em;
      }

      &:focus {
        outline: none;
      }

      label {
        padding: 0 0.35em 0.75em 0.35em;
        border-bottom: 0.125rem solid transparent;
      }

      &__active {
        label {
          border-color: #4f81bd;
        }
      }
    }

    .wdk-TabContent {
      border-color: #c0c0c0;
    }
  }

  &--NewSearchCheckbox {
    width: 50%;
    margin: auto;
  }
}

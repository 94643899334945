.Count-Loading {
  animation: loading-count cubic-bezier(0.65, 0.05, 0.36, 1) 2s infinite;
  display: inline-block;
}

.Count-Error {
  display: inline-block;
  color: darkred;
  font-weight: bold;
  padding: 0 0.1em;
}

@keyframes loading-count {
  0% {
    color: black;
  }
  50% {
    color: #eee;
  }
  100% {
    color: black;
  }
}

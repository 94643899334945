ul.wdk-RadioList {
  list-style: none;
  margin: 0;
  padding-left: 1em;
  margin-left: -1em;
}

ul.wdk-RadioList li {
  margin: 0.4em 0;
}

ul.wdk-RadioList li.disabled {
  color: gray;
}

ul.wdk-RadioList li span {
  margin-left: 0.5em;
}

.wdk-RadioListInfoIcon {
  color: blue;
  margin: 1px 16px;
  font-size: 1.2em;
}

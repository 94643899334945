/* clearfix */
.wdk-Record:after {
  visibility: hidden;
  display: block;
  font-size: 0;
  content: ' ';
  clear: both;
  height: 0;
}

.wdk-RecordActions {
  padding: 0;
  margin: 0;
}

.wdk-RecordHeading {
  clear: both;
  padding: 0.5rem 0 1rem 0;
  position: sticky;
  top: 0;
  z-index: 2;
  background-color: white;
}

.wdk-RecordActionItem {
  display: inline-block;
  padding: 0 2em 0 0;
}

.wdk-RecordContainer {
  position: relative;
  height: 100%;
}

.wdk-RecordSidebarContainer {
  display: flex;
  flex-direction: row;
}

.wdk-RecordMain,
.wdk-RecordSidebar {
  padding: 2.5em 0;
  transition: margin-left 250ms ease;
  transition: left 250ms ease;
  position: relative;
}

.wdk-RecordMain {
  /* make prevent contents panel from being clipped. */
  min-height: 65vh;
  margin-left: 2em;
  width: 100%;
}

.wdk-RecordSidebar {
  position: sticky;
  left: 0;
  top: calc(var(--page-offset-top, 0px) - 2.5em);
  align-self: flex-start;
  height: auto;
  width: 340px;
  padding-right: 1em;
  transition: right 250ms ease;
  z-index: 10;
  background: white;
  flex-basis: 0px;

  .wdk-RecordNavigationSection {
    font-size: 1.35em;
    padding-top: 0.5em;

    .wdk-RecordNavigationSectionHeader {
      display: flex;
      justify-content: space-between;
      align-items: baseline;
      h1 {
        font-size: 1.3em;
        font-weight: 400;
        padding: 0;
        max-width: 85%;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
  }
}

.wdk-RecordSidebarHeader {
  font-weight: 400;
}

.wdk-RecordSidebarToggle {
  background: white;
  z-index: 1;
  font-size: 1.3em;
  transform: rotate(-90deg);
  transform-origin: bottom left;
  white-space: nowrap;
  border: 1px solid;
  padding: 4px 8px;
  position: absolute;
  left: 8px;
  top: 250%;
}

h2.wdk-RecordNavigationSectionHeader {
  padding-right: 1em;
}

.wdk-RecordSidebarToggle,
h2.wdk-RecordNavigationSectionHeader {
  word-wrap: break-word;
  color: black;
  font-weight: 400;
}

.wdk-RecordNavigationItem {
  display: block;
  margin: 0;
  padding: 0.45em;
  border-radius: 0.35em;
}

.wdk-RecordNavigationItem__active {
  background: #d5eaf5;
}

.wdk-RecordNavigationItem__field {
  font-size: 0.9em;
  padding: 0.35em 0.5em 0.35em 0.75em;
}

.wdk-RecordContainer__withSidebar {
  .wdk-RecordSidebar {
    flex: 0 0 350px;
  }
  .wdk-RecordMain {
    max-width: calc(100% - 2em - 350px);
  }
}

.wdk-RecordContainer__withSidebar .wdk-RecordSidebarToggle {
  position: unset;
  border: 0;
  transform: rotate(-90deg);
  transform-origin: initial;
  transition: color 50ms;
  background: none;

  &:hover {
    background: none;
    color: #333333;
  }
}

.wdk-RecordNavigationSection .wdk-RealTimeSearchBox {
  width: 100%;
  width: calc(100% - 1rem);
}

.wdk-RecordNavigationSection .wdk-CheckboxTree > .wdk-CheckboxTreeList {
  max-height: 70vh;
  overflow-y: auto;
  position: relative;
}

.wdk-RecordNavigationSection .wdk-CheckboxTreeNodeWrapper {
  padding: 4px 0 4px 1.5em;
}

.wdk-RecordNavigationSearchInput {
  width: calc(100% - 1.5em);
  padding: 0.5em;
}

.wdk-RecordNavigationCategories {
  overflow-y: auto;
  max-height: calc(100vh - 150px);
  padding-bottom: 1em;
}

.wdk-Record-sidebar > ul {
  padding: 0;
  margin: 0;
}

.wdk-RecordSectionHeader {
  font-size: 1.9em;
  font-weight: 400;
  position: relative;
  cursor: default;
  & + div + & {
    margin-block-start: 1em;
  }
}

.wdk-RecordSectionChildren,
.wdk-RecordSubsectionContent {
  display: flex;
  flex-direction: column;

  > .wdk-CollapsibleSection {
    padding-block-start: 0.5em;
  }
}

.wdk-RecordSectionLink {
  visibility: hidden;
  margin-left: 1ex;
}

.wdk-RecordSectionHeader:hover .wdk-RecordSectionLink,
.wdk-RecordSubsectionHeader:hover .wdk-RecordSectionLink {
  visibility: visible;
}

.wdk-CollapsibleSectionHeader:before {
  content: '\f107'; /* angle-down */
  margin-right: 1ex;
  width: 1ex;
  transition: transform 150ms;
}
.wdk-CollapsibleSectionHeader__collapsed:before {
  transform: rotate(-90deg);
}

.wdk-RecordSectionHeader:before,
.wdk-RecordSectionHeader__collapsed:before {
  display: none;
}

.wdk-RecordSectionContent {
  padding-left: 0;
}

.wdk-RecordSubsectionHeader {
  position: relative;
  border-bottom: 1px dotted;
  cursor: pointer;
}

.wdk-RecordSectionChildren,
.wdk-RecordSubsectionContent {
  .wdk-RecordAttributeInline {
    display: flex;
    align-items: center;
    gap: 1em;
    position: relative;
    padding: 0.5em 0.75em;
  }
}

.wdk-RecordAttributeName {
  font-size: 1.2em;
  font-weight: 400;
}

.wdk-RecordAttributeValue {
  word-break: break-word;
  top: 1px;
}

.wdk-RecordTableContainerContent,
.wdk-RecordAttributeSectionItemContent {
  font-size: 1.2em;
}

.wdk-RecordTableContainerContent,
.wdk-RecordAttributeSectionItemContent,
.wdk-RecordSubsectionContent {
  padding: 1em;
  margin: 0;
  border: 1px solid #e0e0e0;
  border-top: none;
}

.wdk-RecordMain .wdk-CollapsibleSectionHeader:not(.wdk-RecordSectionHeader) {
  --border-radius: 0.5em;
  font-size: 1.2em;
  font-weight: 500;
  background: #dddddd;
  color: #222222;
  padding: 0.5em 0.75em;
  border-top-right-radius: var(--border-radius);
  border-top-left-radius: var(--border-radius);
  border: 1px solid #cccccc;

  &:hover,
  button:focus > & {
    background: #dadada;
  }
}

.wdk-RecordMain
  .wdk-CollapsibleSectionHeader__collapsed:not(.wdk-RecordSectionHeader) {
  border-bottom-left-radius: var(--border-radius);
  border-bottom-right-radius: var(--border-radius);
}

.wdk-RecordMain .wdk-CollapsibleSectionHeader.wdk-RecordSubsectionHeader {
  font-size: 1.5em;
}

.wdk-RecordTableContainerContent .wdk-DataTableContainer {
  max-width: 100%;
  overflow-x: auto;
}

.wdk-RecordTableList {
  vertical-align: top;
  display: inline-block;
  min-width: 20%;
}

.wdk-Compressed.wdk-RecordContainer {
  font-size: 0.8em;
}

.wdk-Compressed.wdk-RecordContainer h1 {
  font-size: 1em;
}

.wdk-Compressed .wdk-RecordMain {
  padding: 0;
}

.wdk-Compressed .wdk-DataTableContainer {
  max-height: 50vh;
}

.wdk-Compressed .wdk-DataTableContainer thead {
  position: sticky;
  top: 0;
}

.wdk-Compressed .wdk-DataTable th,
.wdk-Compressed .wdk-DataTable td {
  padding: 4px;
  border-color: #d0d0d0;
}

.wdk-Compressed .wdk-RecordSidebar {
  display: none;
}

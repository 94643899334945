.Strategy-Notifications {
  padding: 0.5em 2em;
  position: absolute;
  right: 0;
  top: 0;
  width: 25em;
  z-index: 1;
}

.Strategy-Notifications__entry {
  background: #e6f9ff;
  border-radius: 0.4em;
  border: 1px solid #b7e4f3;
  display: flex;
  align-items: center;
  margin: 0.4em 0;
  padding: 1em 0.5em;
  width: 100%;
}

.Strategy-Notifications__entry > .fa {
  font-size: 1.2em;
  margin-right: 0.5em;
}

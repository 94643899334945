.SaveStrategyForm {
  text-align: left;
  width: 600px;
}

.SaveStrategyForm--Notes {
  text-align: left;
  padding: 1em 2em;
  border-radius: 0.5em;
}

.SaveStrategyForm--Notes .important {
  color: #bf1414;
  margin: 0 0 0.5em 0;
}

.SaveStrategyForm--Buttons {
  text-align: center;
}

.SaveStrategyForm--Label {
  display: block;
  font-size: 1.2em;
  margin-top: 1em;
  margin-bottom: 0.2em;
}

textarea.SaveStrategyForm--Control,
input[type='text'].SaveStrategyForm--Control {
  width: 100%;
}

input[type='checkbox'].SaveStrategyForm--Control {
  margin-right: 1em;
  vertical-align: middle;
}

.SaveStrategyForm--Caption {
  font-size: 0.9em;
  color: #222222;
}

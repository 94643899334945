.CombineStepForm {
  &--Header {
    text-align: center;
  }

  &--BooleanOperatorMenu {
    margin: 1em 0;
    font-size: 1.5em;
  }
}

.WorkspaceNavigation {
  border-bottom: 1px solid #ccc;

  &--Item {
    font-size: 1.4em;
    display: inline-block;
    padding: 0.2em 0;
    text-decoration: none !important;
    border-bottom: 3px solid transparent;
    position: relative;
    bottom: -1px;
    margin-right: 1em;

    &:not(:hover):not(:active):not(:focus):not(&__active) {
      color: #1d1d1d;
    }

    &__active {
      border-bottom: 3px solid;
    }
  }
}

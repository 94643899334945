@media (max-width: 479px) {
  .quantized {
    max-width: none;
  }
}
@media (min-width: 480px) and (max-width: 767px) {
  .quantized {
    max-width: 470px;
  }
}
@media (min-width: 768px) and (max-width: 1199px) {
  .quantized {
    max-width: 750px;
  }
}
@media (min-width: 1200px) and (max-width: 1499px) {
  .quantized {
    max-width: 1045px;
  }
}
@media (min-width: 1500px) {
  .quantized {
    max-width: 1250px;
  }
}
.hide {
  display: none;
}
.show {
  display: block;
}
@media (max-width: 479px) {
  .xs-hide,
  [hide\:xs] {
    display: none;
  }
}
@media (max-width: 479px) {
  .xs-show,
  [show\:xs] {
    display: block;
  }
}
@media (max-width: 767px) {
  ._sm-hide {
    display: none;
  }
}
@media (min-width: 480px) and (max-width: 767px) {
  .sm-hide {
    display: none;
  }
}
@media (min-width: 480px) {
  .sm_-hide {
    display: none;
  }
}
@media (max-width: 767px) {
  ._sm-show {
    display: block;
  }
}
@media (min-width: 480px) and (max-width: 767px) {
  .sm-show {
    display: block;
  }
}
@media (min-width: 480px) {
  .sm_-show {
    display: block;
  }
}
@media (max-width: 1199px) {
  ._md-hide {
    display: none;
  }
}
@media (min-width: 768px) and (max-width: 1199px) {
  .md-hide {
    display: none;
  }
}
@media (min-width: 768px) {
  .md_-hide {
    display: none;
  }
}
@media (max-width: 1199px) {
  ._md-show {
    display: block;
  }
}
@media (min-width: 768px) and (max-width: 1199px) {
  .md-show {
    display: block;
  }
}
@media (min-width: 768px) {
  .md_-show {
    display: block;
  }
}
@media (max-width: 1499px) {
  ._lg-hide {
    display: none;
  }
}
@media (min-width: 1200px) and (max-width: 1499px) {
  .lg-hide {
    display: none;
  }
}
@media (min-width: 1200px) {
  .lg_-hide {
    display: none;
  }
}
@media (max-width: 1499px) {
  ._lg-show {
    display: block;
  }
}
@media (min-width: 1200px) and (max-width: 1499px) {
  .lg-show {
    display: block;
  }
}
@media (min-width: 1200px) {
  .lg_-show {
    display: block;
  }
}
@media (min-width: 1500px) {
  .xl-hide {
    display: none;
  }
}
@media (min-width: 1500px) {
  .xl-show {
    display: block;
  }
}
.wrap,
[wrap] {
  flex-wrap: wrap;
}
.nowrap,
[nowrap] {
  flex-wrap: nowrap;
}
grid,
.grid {
  box-sizing: border-box;
  display: flex;
  flex: none;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  flex-wrap: wrap;
}
@media (max-width: 479px) {
  grid,
  .grid {
    max-width: none;
  }
}
@media (min-width: 480px) and (max-width: 767px) {
  grid,
  .grid {
    max-width: 470px;
  }
}
@media (min-width: 768px) and (max-width: 1199px) {
  grid,
  .grid {
    max-width: 750px;
  }
}
@media (min-width: 1200px) and (max-width: 1499px) {
  grid,
  .grid {
    max-width: 1045px;
  }
}
@media (min-width: 1500px) {
  grid,
  .grid {
    max-width: 1250px;
  }
}
row,
.row {
  box-sizing: border-box;
  display: flex;
  flex: none;
  flex-basis: 100%;
  flex-wrap: wrap;
  width: 100%;
  flex-direction: row;
}
row.reverse,
.row.reverse,
row[reverse],
.row[reverse] {
  flex-direction: row-reverse;
}
stack,
.stack {
  box-sizing: border-box;
  display: flex;
  flex: none;
  flex-basis: 100%;
  flex-wrap: wrap;
  height: 100%;
  flex-direction: column;
}
stack.reverse,
.stack.reverse,
stack[reverse],
.stack[reverse] {
  flex-direction: column-reverse;
}
box,
.box,
[box] {
  box-sizing: border-box;
  flex: none;
}
row-grid,
.row-grid {
  flex-direction: row;
  box-sizing: border-box;
  display: flex;
  flex: none;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  flex-wrap: wrap;
}
row-grid.reverse,
.row-grid.reverse,
row-grid[reverse],
.row-grid[reverse] {
  flex-direction: row-reverse;
}
@media (max-width: 479px) {
  row-grid,
  .row-grid {
    max-width: none;
  }
}
@media (min-width: 480px) and (max-width: 767px) {
  row-grid,
  .row-grid {
    max-width: 470px;
  }
}
@media (min-width: 768px) and (max-width: 1199px) {
  row-grid,
  .row-grid {
    max-width: 750px;
  }
}
@media (min-width: 1200px) and (max-width: 1499px) {
  row-grid,
  .row-grid {
    max-width: 1045px;
  }
}
@media (min-width: 1500px) {
  row-grid,
  .row-grid {
    max-width: 1250px;
  }
}
stack-grid,
.stack-grid {
  height: 100%;
  flex-direction: column;
  box-sizing: border-box;
  display: flex;
  flex: none;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  flex-wrap: wrap;
}
stack-grid.reverse,
.stack-grid.reverse,
stack-grid[reverse],
.stack-grid[reverse] {
  flex-direction: column-reverse;
}
@media (max-width: 479px) {
  stack-grid,
  .stack-grid {
    max-width: none;
  }
}
@media (min-width: 480px) and (max-width: 767px) {
  stack-grid,
  .stack-grid {
    max-width: 470px;
  }
}
@media (min-width: 768px) and (max-width: 1199px) {
  stack-grid,
  .stack-grid {
    max-width: 750px;
  }
}
@media (min-width: 1200px) and (max-width: 1499px) {
  stack-grid,
  .stack-grid {
    max-width: 1045px;
  }
}
@media (min-width: 1500px) {
  stack-grid,
  .stack-grid {
    max-width: 1250px;
  }
}
grid-fluid,
.grid-fluid {
  box-sizing: border-box;
  display: flex;
  flex: none;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  flex-wrap: wrap;
}
.box-x1,
.flex-1,
[flex="1"],
[flex\:1] {
  flex: 1;
}
.box-x2,
.flex-2,
[flex="2"],
[flex\:2] {
  flex: 2;
}
.box-x3,
.flex-3,
[flex="3"],
[flex\:3] {
  flex: 3;
}
.box-x4,
.flex-4,
[flex="4"],
[flex\:4] {
  flex: 4;
}
.box-x5,
.flex-5,
[flex="5"],
[flex\:5] {
  flex: 5;
}
.box-x6,
.flex-6,
[flex="6"],
[flex\:6] {
  flex: 6;
}
.box-x7,
.flex-7,
[flex="7"],
[flex\:7] {
  flex: 7;
}
.box-x8,
.flex-8,
[flex="8"],
[flex\:8] {
  flex: 8;
}
.box-x9,
.flex-9,
[flex="9"],
[flex\:9] {
  flex: 9;
}
.box-x10,
.flex-10,
[flex="10"],
[flex\:10] {
  flex: 10;
}
.box-auto,
.flex-auto,
[flex="auto"],
[flex\:auto] {
  flex: auto;
}
.box-0 {
  flex: 0;
}
.box-1 {
  flex: 0 1 8.33%;
}
.box-2 {
  flex: 0 1 16.66%;
}
.box-3 {
  flex: 0 1 25%;
}
.box-4 {
  flex: 0 1 33.33%;
}
.box-5 {
  flex: 0 1 41.66%;
}
.box-6 {
  flex: 0 1 50%;
}
.box-7 {
  flex: 0 1 58.33%;
}
.box-8 {
  flex: 0 1 66.66%;
}
.box-9 {
  flex: 0 1 75%;
}
.box-10 {
  flex: 0 1 83.33%;
}
.box-11 {
  flex: 0 1 91.66%;
}
.box-12 {
  flex: 0 1 100%;
}
.box-auto {
  flex: 0 1 auto;
}
@media (min-width: 0px) {
  .xs-0,
  .box-xs-0,
  [xs="0"],
  [xs\:0] {
    flex: 0;
  }
  .xs-1,
  .box-xs-1,
  [xs="1"],
  [xs\:1] {
    flex: 0 1 8.33%;
  }
  .xs-2,
  .box-xs-2,
  [xs="2"],
  [xs\:2] {
    flex: 0 1 16.66%;
  }
  .xs-3,
  .box-xs-3,
  [xs="3"],
  [xs\:3] {
    flex: 0 1 25%;
  }
  .xs-4,
  .box-xs-4,
  [xs="4"],
  [xs\:4] {
    flex: 0 1 33.33%;
  }
  .xs-5,
  .box-xs-5,
  [xs="5"],
  [xs\:5] {
    flex: 0 1 41.66%;
  }
  .xs-6,
  .box-xs-6,
  [xs="6"],
  [xs\:6] {
    flex: 0 1 50%;
  }
  .xs-7,
  .box-xs-7,
  [xs="7"],
  [xs\:7] {
    flex: 0 1 58.33%;
  }
  .xs-8,
  .box-xs-8,
  [xs="8"],
  [xs\:8] {
    flex: 0 1 66.66%;
  }
  .xs-9,
  .box-xs-9,
  [xs="9"],
  [xs\:9] {
    flex: 0 1 75%;
  }
  .xs-10,
  .box-xs-10,
  [xs="10"],
  [xs\:10] {
    flex: 0 1 83.33%;
  }
  .xs-11,
  .box-xs-11,
  [xs="11"],
  [xs\:11] {
    flex: 0 1 91.66%;
  }
  .xs-12,
  .box-xs-12,
  [xs="12"],
  [xs\:12] {
    flex: 0 1 100%;
  }
  .xs-auto,
  .box-xs-auto,
  [xs="auto"],
  [xs\:auto] {
    flex: auto;
    flex: 0 1 auto;
  }
}
@media (min-width: 480px) {
  .sm-0,
  .box-sm-0,
  [sm="0"],
  [sm\:0] {
    flex: 0;
  }
  .sm-1,
  .box-sm-1,
  [sm="1"],
  [sm\:1] {
    flex: 0 1 8.33%;
  }
  .sm-2,
  .box-sm-2,
  [sm="2"],
  [sm\:2] {
    flex: 0 1 16.66%;
  }
  .sm-3,
  .box-sm-3,
  [sm="3"],
  [sm\:3] {
    flex: 0 1 25%;
  }
  .sm-4,
  .box-sm-4,
  [sm="4"],
  [sm\:4] {
    flex: 0 1 33.33%;
  }
  .sm-5,
  .box-sm-5,
  [sm="5"],
  [sm\:5] {
    flex: 0 1 41.66%;
  }
  .sm-6,
  .box-sm-6,
  [sm="6"],
  [sm\:6] {
    flex: 0 1 50%;
  }
  .sm-7,
  .box-sm-7,
  [sm="7"],
  [sm\:7] {
    flex: 0 1 58.33%;
  }
  .sm-8,
  .box-sm-8,
  [sm="8"],
  [sm\:8] {
    flex: 0 1 66.66%;
  }
  .sm-9,
  .box-sm-9,
  [sm="9"],
  [sm\:9] {
    flex: 0 1 75%;
  }
  .sm-10,
  .box-sm-10,
  [sm="10"],
  [sm\:10] {
    flex: 0 1 83.33%;
  }
  .sm-11,
  .box-sm-11,
  [sm="11"],
  [sm\:11] {
    flex: 0 1 91.66%;
  }
  .sm-12,
  .box-sm-12,
  [sm="12"],
  [sm\:12] {
    flex: 0 1 100%;
  }
  .sm-auto,
  .box-sm-auto,
  [sm="auto"],
  [sm\:auto] {
    flex: auto;
    flex: 0 1 auto;
  }
}
@media (min-width: 768px) {
  .md-0,
  .box-md-0,
  [md="0"],
  [md\:0] {
    flex: 0;
  }
  .md-1,
  .box-md-1,
  [md="1"],
  [md\:1] {
    flex: 0 1 8.33%;
  }
  .md-2,
  .box-md-2,
  [md="2"],
  [md\:2] {
    flex: 0 1 16.66%;
  }
  .md-3,
  .box-md-3,
  [md="3"],
  [md\:3] {
    flex: 0 1 25%;
  }
  .md-4,
  .box-md-4,
  [md="4"],
  [md\:4] {
    flex: 0 1 33.33%;
  }
  .md-5,
  .box-md-5,
  [md="5"],
  [md\:5] {
    flex: 0 1 41.66%;
  }
  .md-6,
  .box-md-6,
  [md="6"],
  [md\:6] {
    flex: 0 1 50%;
  }
  .md-7,
  .box-md-7,
  [md="7"],
  [md\:7] {
    flex: 0 1 58.33%;
  }
  .md-8,
  .box-md-8,
  [md="8"],
  [md\:8] {
    flex: 0 1 66.66%;
  }
  .md-9,
  .box-md-9,
  [md="9"],
  [md\:9] {
    flex: 0 1 75%;
  }
  .md-10,
  .box-md-10,
  [md="10"],
  [md\:10] {
    flex: 0 1 83.33%;
  }
  .md-11,
  .box-md-11,
  [md="11"],
  [md\:11] {
    flex: 0 1 91.66%;
  }
  .md-12,
  .box-md-12,
  [md="12"],
  [md\:12] {
    flex: 0 1 100%;
  }
  .md-auto,
  .box-md-auto,
  [md="auto"],
  [md\:auto] {
    flex: auto;
    flex: 0 1 auto;
  }
}
@media (min-width: 1200px) {
  .lg-0,
  .box-lg-0,
  [lg="0"],
  [lg\:0] {
    flex: 0;
  }
  .lg-1,
  .box-lg-1,
  [lg="1"],
  [lg\:1] {
    flex: 0 1 8.33%;
  }
  .lg-2,
  .box-lg-2,
  [lg="2"],
  [lg\:2] {
    flex: 0 1 16.66%;
  }
  .lg-3,
  .box-lg-3,
  [lg="3"],
  [lg\:3] {
    flex: 0 1 25%;
  }
  .lg-4,
  .box-lg-4,
  [lg="4"],
  [lg\:4] {
    flex: 0 1 33.33%;
  }
  .lg-5,
  .box-lg-5,
  [lg="5"],
  [lg\:5] {
    flex: 0 1 41.66%;
  }
  .lg-6,
  .box-lg-6,
  [lg="6"],
  [lg\:6] {
    flex: 0 1 50%;
  }
  .lg-7,
  .box-lg-7,
  [lg="7"],
  [lg\:7] {
    flex: 0 1 58.33%;
  }
  .lg-8,
  .box-lg-8,
  [lg="8"],
  [lg\:8] {
    flex: 0 1 66.66%;
  }
  .lg-9,
  .box-lg-9,
  [lg="9"],
  [lg\:9] {
    flex: 0 1 75%;
  }
  .lg-10,
  .box-lg-10,
  [lg="10"],
  [lg\:10] {
    flex: 0 1 83.33%;
  }
  .lg-11,
  .box-lg-11,
  [lg="11"],
  [lg\:11] {
    flex: 0 1 91.66%;
  }
  .lg-12,
  .box-lg-12,
  [lg="12"],
  [lg\:12] {
    flex: 0 1 100%;
  }
  .lg-auto,
  .box-lg-auto,
  [lg="auto"],
  [lg\:auto] {
    flex: auto;
    flex: 0 1 auto;
  }
}
@media (min-width: 1500px) {
  .xl-0,
  .box-xl-0,
  [xl="0"],
  [xl\:0] {
    flex: 0;
  }
  .xl-1,
  .box-xl-1,
  [xl="1"],
  [xl\:1] {
    flex: 0 1 8.33%;
  }
  .xl-2,
  .box-xl-2,
  [xl="2"],
  [xl\:2] {
    flex: 0 1 16.66%;
  }
  .xl-3,
  .box-xl-3,
  [xl="3"],
  [xl\:3] {
    flex: 0 1 25%;
  }
  .xl-4,
  .box-xl-4,
  [xl="4"],
  [xl\:4] {
    flex: 0 1 33.33%;
  }
  .xl-5,
  .box-xl-5,
  [xl="5"],
  [xl\:5] {
    flex: 0 1 41.66%;
  }
  .xl-6,
  .box-xl-6,
  [xl="6"],
  [xl\:6] {
    flex: 0 1 50%;
  }
  .xl-7,
  .box-xl-7,
  [xl="7"],
  [xl\:7] {
    flex: 0 1 58.33%;
  }
  .xl-8,
  .box-xl-8,
  [xl="8"],
  [xl\:8] {
    flex: 0 1 66.66%;
  }
  .xl-9,
  .box-xl-9,
  [xl="9"],
  [xl\:9] {
    flex: 0 1 75%;
  }
  .xl-10,
  .box-xl-10,
  [xl="10"],
  [xl\:10] {
    flex: 0 1 83.33%;
  }
  .xl-11,
  .box-xl-11,
  [xl="11"],
  [xl\:11] {
    flex: 0 1 91.66%;
  }
  .xl-12,
  .box-xl-12,
  [xl="12"],
  [xl\:12] {
    flex: 0 1 100%;
  }
  .xl-auto,
  .box-xl-auto,
  [xl="auto"],
  [xl\:auto] {
    flex: auto;
    flex: 0 1 auto;
  }
}
.order-5n,
[order="5n"],
[order\:5n] {
  order: -5;
}
.order-4n,
[order="4n"],
[order\:4n] {
  order: -4;
}
.order-3n,
[order="3n"],
[order\:3n] {
  order: -3;
}
.order-2n,
[order="2n"],
[order\:2n] {
  order: -2;
}
.order-1n,
[order="1n"],
[order\:1n] {
  order: -1;
}
.order-0,
[order="0"],
[order\:0] {
  order: 0;
}
.order-1,
[order="1"],
[order\:1] {
  order: 1;
}
.order-2,
[order="2"],
[order\:2] {
  order: 2;
}
.order-3,
[order="3"],
[order\:3] {
  order: 3;
}
.order-4,
[order="4"],
[order\:4] {
  order: 4;
}
.order-5,
[order="5"],
[order\:5] {
  order: 5;
}
@media (max-width: 479px) {
  .xs-order-5n,
  [xs-order="5n"],
  [xs-order\:5n] {
    order: -5;
  }
  .xs-order-4n,
  [xs-order="4n"],
  [xs-order\:4n] {
    order: -4;
  }
  .xs-order-3n,
  [xs-order="3n"],
  [xs-order\:3n] {
    order: -3;
  }
  .xs-order-2n,
  [xs-order="2n"],
  [xs-order\:2n] {
    order: -2;
  }
  .xs-order-1n,
  [xs-order="1n"],
  [xs-order\:1n] {
    order: -1;
  }
  .xs-order-0,
  [xs-order="0"],
  [xs-order\:0] {
    order: 0;
  }
  .xs-order-1,
  [xs-order="1"],
  [xs-order\:1] {
    order: 1;
  }
  .xs-order-2,
  [xs-order="2"],
  [xs-order\:2] {
    order: 2;
  }
  .xs-order-3,
  [xs-order="3"],
  [xs-order\:3] {
    order: 3;
  }
  .xs-order-4,
  [xs-order="4"],
  [xs-order\:4] {
    order: 4;
  }
  .xs-order-5,
  [xs-order="5"],
  [xs-order\:5] {
    order: 5;
  }
}
@media (min-width: 480px) and (max-width: 767px) {
  .sm-order-5n,
  [sm-order="5n"],
  [sm-order\:5n] {
    order: -5;
  }
  .sm-order-4n,
  [sm-order="4n"],
  [sm-order\:4n] {
    order: -4;
  }
  .sm-order-3n,
  [sm-order="3n"],
  [sm-order\:3n] {
    order: -3;
  }
  .sm-order-2n,
  [sm-order="2n"],
  [sm-order\:2n] {
    order: -2;
  }
  .sm-order-1n,
  [sm-order="1n"],
  [sm-order\:1n] {
    order: -1;
  }
  .sm-order-0,
  [sm-order="0"],
  [sm-order\:0] {
    order: 0;
  }
  .sm-order-1,
  [sm-order="1"],
  [sm-order\:1] {
    order: 1;
  }
  .sm-order-2,
  [sm-order="2"],
  [sm-order\:2] {
    order: 2;
  }
  .sm-order-3,
  [sm-order="3"],
  [sm-order\:3] {
    order: 3;
  }
  .sm-order-4,
  [sm-order="4"],
  [sm-order\:4] {
    order: 4;
  }
  .sm-order-5,
  [sm-order="5"],
  [sm-order\:5] {
    order: 5;
  }
}
@media (min-width: 768px) and (max-width: 1199px) {
  .md-order-5n,
  [md-order="5n"],
  [md-order\:5n] {
    order: -5;
  }
  .md-order-4n,
  [md-order="4n"],
  [md-order\:4n] {
    order: -4;
  }
  .md-order-3n,
  [md-order="3n"],
  [md-order\:3n] {
    order: -3;
  }
  .md-order-2n,
  [md-order="2n"],
  [md-order\:2n] {
    order: -2;
  }
  .md-order-1n,
  [md-order="1n"],
  [md-order\:1n] {
    order: -1;
  }
  .md-order-0,
  [md-order="0"],
  [md-order\:0] {
    order: 0;
  }
  .md-order-1,
  [md-order="1"],
  [md-order\:1] {
    order: 1;
  }
  .md-order-2,
  [md-order="2"],
  [md-order\:2] {
    order: 2;
  }
  .md-order-3,
  [md-order="3"],
  [md-order\:3] {
    order: 3;
  }
  .md-order-4,
  [md-order="4"],
  [md-order\:4] {
    order: 4;
  }
  .md-order-5,
  [md-order="5"],
  [md-order\:5] {
    order: 5;
  }
}
@media (min-width: 1200px) and (max-width: 1499px) {
  .lg-order-5n,
  [lg-order="5n"],
  [lg-order\:5n] {
    order: -5;
  }
  .lg-order-4n,
  [lg-order="4n"],
  [lg-order\:4n] {
    order: -4;
  }
  .lg-order-3n,
  [lg-order="3n"],
  [lg-order\:3n] {
    order: -3;
  }
  .lg-order-2n,
  [lg-order="2n"],
  [lg-order\:2n] {
    order: -2;
  }
  .lg-order-1n,
  [lg-order="1n"],
  [lg-order\:1n] {
    order: -1;
  }
  .lg-order-0,
  [lg-order="0"],
  [lg-order\:0] {
    order: 0;
  }
  .lg-order-1,
  [lg-order="1"],
  [lg-order\:1] {
    order: 1;
  }
  .lg-order-2,
  [lg-order="2"],
  [lg-order\:2] {
    order: 2;
  }
  .lg-order-3,
  [lg-order="3"],
  [lg-order\:3] {
    order: 3;
  }
  .lg-order-4,
  [lg-order="4"],
  [lg-order\:4] {
    order: 4;
  }
  .lg-order-5,
  [lg-order="5"],
  [lg-order\:5] {
    order: 5;
  }
}
@media (min-width: 1500px) {
  .xl-order-5n,
  [xl-order="5n"],
  [xl-order\:5n] {
    order: -5;
  }
  .xl-order-4n,
  [xl-order="4n"],
  [xl-order\:4n] {
    order: -4;
  }
  .xl-order-3n,
  [xl-order="3n"],
  [xl-order\:3n] {
    order: -3;
  }
  .xl-order-2n,
  [xl-order="2n"],
  [xl-order\:2n] {
    order: -2;
  }
  .xl-order-1n,
  [xl-order="1n"],
  [xl-order\:1n] {
    order: -1;
  }
  .xl-order-0,
  [xl-order="0"],
  [xl-order\:0] {
    order: 0;
  }
  .xl-order-1,
  [xl-order="1"],
  [xl-order\:1] {
    order: 1;
  }
  .xl-order-2,
  [xl-order="2"],
  [xl-order\:2] {
    order: 2;
  }
  .xl-order-3,
  [xl-order="3"],
  [xl-order\:3] {
    order: 3;
  }
  .xl-order-4,
  [xl-order="4"],
  [xl-order\:4] {
    order: 4;
  }
  .xl-order-5,
  [xl-order="5"],
  [xl-order\:5] {
    order: 5;
  }
}
.grow-0,
[grow="0"],
[grow\:0] {
  flex-grow: 0;
}
.grow-1,
[grow="1"],
[grow\:1] {
  flex-grow: 1;
}
.grow-2,
[grow="2"],
[grow\:2] {
  flex-grow: 2;
}
.grow-3,
[grow="3"],
[grow\:3] {
  flex-grow: 3;
}
.grow-4,
[grow="4"],
[grow\:4] {
  flex-grow: 4;
}
.grow-5,
[grow="5"],
[grow\:5] {
  flex-grow: 5;
}
.grow-6,
[grow="6"],
[grow\:6] {
  flex-grow: 6;
}
.grow-7,
[grow="7"],
[grow\:7] {
  flex-grow: 7;
}
.grow-8,
[grow="8"],
[grow\:8] {
  flex-grow: 8;
}
.grow-9,
[grow="9"],
[grow\:9] {
  flex-grow: 9;
}
.grow-10,
[grow="10"],
[grow\:10] {
  flex-grow: 10;
}
.shrink-0,
[shrink="0"],
[shrink\:0] {
  flex-shrink: 0;
}
.shrink-1,
[shrink="1"],
[shrink\:1] {
  flex-shrink: 1;
}
.shrink-2,
[shrink="2"],
[shrink\:2] {
  flex-shrink: 2;
}
.shrink-3,
[shrink="3"],
[shrink\:3] {
  flex-shrink: 3;
}
.shrink-4,
[shrink="4"],
[shrink\:4] {
  flex-shrink: 4;
}
.shrink-5,
[shrink="5"],
[shrink\:5] {
  flex-shrink: 5;
}
.shrink-6,
[shrink="6"],
[shrink\:6] {
  flex-shrink: 6;
}
.shrink-7,
[shrink="7"],
[shrink\:7] {
  flex-shrink: 7;
}
.shrink-8,
[shrink="8"],
[shrink\:8] {
  flex-shrink: 8;
}
.shrink-9,
[shrink="9"],
[shrink\:9] {
  flex-shrink: 9;
}
.shrink-10,
[shrink="10"],
[shrink\:10] {
  flex-shrink: 10;
}
.justify-center,
[justify="center"],
[justify\:center] {
  justify-content: center;
}
.justify-end,
[justify="end"],
[justify\:end] {
  justify-content: flex-end;
}
.justify-start,
[justify="start"],
[justify\:start] {
  justify-content: flex-start;
}
.justify-around,
[justify="around"],
[justify\:around] {
  justify-content: space-around;
}
.justify-between,
[justify="between"],
[justify\:between] {
  justify-content: space-between;
}
.items-center,
[items="center"],
[items\:center] {
  align-items: center;
}
.items-stretch,
[items="stretch"],
[items\:stretch] {
  align-items: stretch;
}
.items-end,
[items="end"],
[items\:end] {
  align-items: flex-end;
}
.items-base,
[items="base"],
[items\:base] {
  align-items: baseline;
}
.items-start,
[items="start"],
[items\:start] {
  align-items: flex-start;
}
.self-center,
[self="center"],
[self\:center] {
  align-self: center;
}
.self-base,
[self="base"],
[self\:base] {
  align-self: baseline;
}
.self-end,
[self="end"],
[self\:end] {
  align-self: flex-end;
}
.self-start,
[self="start"],
[self\:start] {
  align-self: flex-start;
}
.lines-center,
[lines="center"],
[lines\:center] {
  align-content: center;
}
.lines-stretch,
[lines="stretch"],
[lines\:stretch] {
  align-content: stretch;
}
.lines-end,
[lines="end"],
[lines\:end] {
  align-content: flex-end;
}
.lines-start,
[lines="start"],
[lines\:start] {
  align-content: flex-start;
}
.lines-around,
[lines="around"],
[lines\:around] {
  align-content: space-around;
}
.lines-between,
[lines="between"],
[lines\:between] {
  align-content: space-between;
}
.offset-0,
[offset="0"],
[offset\:0] {
  margin-left: 0;
}
.offset-1,
[offset="1"],
[offset\:1] {
  margin-left: 8.33%;
}
.offset-2,
[offset="2"],
[offset\:2] {
  margin-left: 16.66%;
}
.offset-3,
[offset="3"],
[offset\:3] {
  margin-left: 25%;
}
.offset-4,
[offset="4"],
[offset\:4] {
  margin-left: 33.33%;
}
.offset-5,
[offset="5"],
[offset\:5] {
  margin-left: 41.66%;
}
.offset-6,
[offset="6"],
[offset\:6] {
  margin-left: 50%;
}
.offset-7,
[offset="7"],
[offset\:7] {
  margin-left: 58.33%;
}
.offset-8,
[offset="8"],
[offset\:8] {
  margin-left: 66.66%;
}
.offset-9,
[offset="9"],
[offset\:9] {
  margin-left: 75%;
}
.offset-10,
[offset="10"],
[offset\:10] {
  margin-left: 83.33%;
}
.offset-11,
[offset="11"],
[offset\:11] {
  margin-left: 91.66%;
}
.offset-12,
[offset="12"],
[offset\:12] {
  margin-left: 100%;
}
@media (min-width: 0px) {
  .xs-offset-0,
  [xs-offset="0"],
  [xs-offset\:0] {
    margin-left: 0;
  }
  .xs-offset-1,
  [xs-offset="1"],
  [xs-offset\:1] {
    margin-left: 8.33%;
  }
  .xs-offset-2,
  [xs-offset="2"],
  [xs-offset\:2] {
    margin-left: 16.66%;
  }
  .xs-offset-3,
  [xs-offset="3"],
  [xs-offset\:3] {
    margin-left: 25%;
  }
  .xs-offset-4,
  [xs-offset="4"],
  [xs-offset\:4] {
    margin-left: 33.33%;
  }
  .xs-offset-5,
  [xs-offset="5"],
  [xs-offset\:5] {
    margin-left: 41.66%;
  }
  .xs-offset-6,
  [xs-offset="6"],
  [xs-offset\:6] {
    margin-left: 50%;
  }
  .xs-offset-7,
  [xs-offset="7"],
  [xs-offset\:7] {
    margin-left: 58.33%;
  }
  .xs-offset-8,
  [xs-offset="8"],
  [xs-offset\:8] {
    margin-left: 66.66%;
  }
  .xs-offset-9,
  [xs-offset="9"],
  [xs-offset\:9] {
    margin-left: 75%;
  }
  .xs-offset-10,
  [xs-offset="10"],
  [xs-offset\:10] {
    margin-left: 83.33%;
  }
  .xs-offset-11,
  [xs-offset="11"],
  [xs-offset\:11] {
    margin-left: 91.66%;
  }
  .xs-offset-12,
  [xs-offset="12"],
  [xs-offset\:12] {
    margin-left: 100%;
  }
}
@media (min-width: 480px) {
  .sm-offset-0,
  [sm-offset="0"],
  [sm-offset\:0] {
    margin-left: 0;
  }
  .sm-offset-1,
  [sm-offset="1"],
  [sm-offset\:1] {
    margin-left: 8.33%;
  }
  .sm-offset-2,
  [sm-offset="2"],
  [sm-offset\:2] {
    margin-left: 16.66%;
  }
  .sm-offset-3,
  [sm-offset="3"],
  [sm-offset\:3] {
    margin-left: 25%;
  }
  .sm-offset-4,
  [sm-offset="4"],
  [sm-offset\:4] {
    margin-left: 33.33%;
  }
  .sm-offset-5,
  [sm-offset="5"],
  [sm-offset\:5] {
    margin-left: 41.66%;
  }
  .sm-offset-6,
  [sm-offset="6"],
  [sm-offset\:6] {
    margin-left: 50%;
  }
  .sm-offset-7,
  [sm-offset="7"],
  [sm-offset\:7] {
    margin-left: 58.33%;
  }
  .sm-offset-8,
  [sm-offset="8"],
  [sm-offset\:8] {
    margin-left: 66.66%;
  }
  .sm-offset-9,
  [sm-offset="9"],
  [sm-offset\:9] {
    margin-left: 75%;
  }
  .sm-offset-10,
  [sm-offset="10"],
  [sm-offset\:10] {
    margin-left: 83.33%;
  }
  .sm-offset-11,
  [sm-offset="11"],
  [sm-offset\:11] {
    margin-left: 91.66%;
  }
  .sm-offset-12,
  [sm-offset="12"],
  [sm-offset\:12] {
    margin-left: 100%;
  }
}
@media (min-width: 768px) {
  .md-offset-0,
  [md-offset="0"],
  [md-offset\:0] {
    margin-left: 0;
  }
  .md-offset-1,
  [md-offset="1"],
  [md-offset\:1] {
    margin-left: 8.33%;
  }
  .md-offset-2,
  [md-offset="2"],
  [md-offset\:2] {
    margin-left: 16.66%;
  }
  .md-offset-3,
  [md-offset="3"],
  [md-offset\:3] {
    margin-left: 25%;
  }
  .md-offset-4,
  [md-offset="4"],
  [md-offset\:4] {
    margin-left: 33.33%;
  }
  .md-offset-5,
  [md-offset="5"],
  [md-offset\:5] {
    margin-left: 41.66%;
  }
  .md-offset-6,
  [md-offset="6"],
  [md-offset\:6] {
    margin-left: 50%;
  }
  .md-offset-7,
  [md-offset="7"],
  [md-offset\:7] {
    margin-left: 58.33%;
  }
  .md-offset-8,
  [md-offset="8"],
  [md-offset\:8] {
    margin-left: 66.66%;
  }
  .md-offset-9,
  [md-offset="9"],
  [md-offset\:9] {
    margin-left: 75%;
  }
  .md-offset-10,
  [md-offset="10"],
  [md-offset\:10] {
    margin-left: 83.33%;
  }
  .md-offset-11,
  [md-offset="11"],
  [md-offset\:11] {
    margin-left: 91.66%;
  }
  .md-offset-12,
  [md-offset="12"],
  [md-offset\:12] {
    margin-left: 100%;
  }
}
@media (min-width: 1200px) {
  .lg-offset-0,
  [lg-offset="0"],
  [lg-offset\:0] {
    margin-left: 0;
  }
  .lg-offset-1,
  [lg-offset="1"],
  [lg-offset\:1] {
    margin-left: 8.33%;
  }
  .lg-offset-2,
  [lg-offset="2"],
  [lg-offset\:2] {
    margin-left: 16.66%;
  }
  .lg-offset-3,
  [lg-offset="3"],
  [lg-offset\:3] {
    margin-left: 25%;
  }
  .lg-offset-4,
  [lg-offset="4"],
  [lg-offset\:4] {
    margin-left: 33.33%;
  }
  .lg-offset-5,
  [lg-offset="5"],
  [lg-offset\:5] {
    margin-left: 41.66%;
  }
  .lg-offset-6,
  [lg-offset="6"],
  [lg-offset\:6] {
    margin-left: 50%;
  }
  .lg-offset-7,
  [lg-offset="7"],
  [lg-offset\:7] {
    margin-left: 58.33%;
  }
  .lg-offset-8,
  [lg-offset="8"],
  [lg-offset\:8] {
    margin-left: 66.66%;
  }
  .lg-offset-9,
  [lg-offset="9"],
  [lg-offset\:9] {
    margin-left: 75%;
  }
  .lg-offset-10,
  [lg-offset="10"],
  [lg-offset\:10] {
    margin-left: 83.33%;
  }
  .lg-offset-11,
  [lg-offset="11"],
  [lg-offset\:11] {
    margin-left: 91.66%;
  }
  .lg-offset-12,
  [lg-offset="12"],
  [lg-offset\:12] {
    margin-left: 100%;
  }
}
@media (min-width: 1500px) {
  .xl-offset-0,
  [xl-offset="0"],
  [xl-offset\:0] {
    margin-left: 0;
  }
  .xl-offset-1,
  [xl-offset="1"],
  [xl-offset\:1] {
    margin-left: 8.33%;
  }
  .xl-offset-2,
  [xl-offset="2"],
  [xl-offset\:2] {
    margin-left: 16.66%;
  }
  .xl-offset-3,
  [xl-offset="3"],
  [xl-offset\:3] {
    margin-left: 25%;
  }
  .xl-offset-4,
  [xl-offset="4"],
  [xl-offset\:4] {
    margin-left: 33.33%;
  }
  .xl-offset-5,
  [xl-offset="5"],
  [xl-offset\:5] {
    margin-left: 41.66%;
  }
  .xl-offset-6,
  [xl-offset="6"],
  [xl-offset\:6] {
    margin-left: 50%;
  }
  .xl-offset-7,
  [xl-offset="7"],
  [xl-offset\:7] {
    margin-left: 58.33%;
  }
  .xl-offset-8,
  [xl-offset="8"],
  [xl-offset\:8] {
    margin-left: 66.66%;
  }
  .xl-offset-9,
  [xl-offset="9"],
  [xl-offset\:9] {
    margin-left: 75%;
  }
  .xl-offset-10,
  [xl-offset="10"],
  [xl-offset\:10] {
    margin-left: 83.33%;
  }
  .xl-offset-11,
  [xl-offset="11"],
  [xl-offset\:11] {
    margin-left: 91.66%;
  }
  .xl-offset-12,
  [xl-offset="12"],
  [xl-offset\:12] {
    margin-left: 100%;
  }
}

.OverflowingTextCell {
  &--OverflowableContents {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    &__expanded {
      white-space: normal;
      overflow: visible;
    }
  }
}

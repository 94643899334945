.vpdb-WorkshopExercises {
  h2 {
    font-size: 2.3em;
    margin: 0.25em 0;
    padding: 0;
    font-weight: normal;
  }

  h3 {
    font-size: 1.66666666667em;
    margin-top: 0;
    margin-bottom: 0.75rem;
    font-weight: normal;
  }

  &Header {
    display: flex;
    justify-content: space-between;
    align-items: center;

    a {
      font-size: 1.2em;
      color: #00304c;
      font-weight: bold;

      i {
        margin-right: 0.25em;
      }
    }
  }

  .vpdb-CardList {
    padding: 0.9375rem;

    &Item {
      width: 250px;
      border-radius: 0.25rem;
      padding: 1.25rem;
      color: white;
      box-shadow: 1px 1px 8px #000000a1;

      p,
      ul {
        line-height: 1.3;
      }

      ul {
        list-style: none;
      }

      h3,
      a {
        color: white;
      }

      &Content {
        font-size: 1.2em;

        .fa-li {
          top: 0;
        }

        &Link {
          text-decoration: underline;
        }
      }
    }

    &__collapsed {
      display: flex;
      overflow-x: auto;

      .vpdb-CardListItem {
        margin-right: 1.5rem;
        flex: 0 0 auto;
      }
    }

    &__expanded {
      display: grid;
      grid-template-columns: repeat(auto-fill, 250px);
      justify-content: center;

      // FIXME: IE 11 does not support grid-gap
      grid-gap: 1.2rem;
    }
  }
}

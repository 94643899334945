$red: #dd314e;
$white: #e0e0e0;

.UserMenu {
  color: #fff;
  flex-grow: 0;
  font-size: 1em;
  display: flex;
  position: relative;
  flex-direction: column;
  padding-left: 2em;
  margin: -0.75em 0;

  .UserMenu-Icon {
    font-size: 30px;
    opacity: 0.5;
  }
  span.UserMenu-Title {
    opacity: 0.7;
    font-weight: 300;
    padding: 5px;
    font-style: italic;
  }

  &:focus-within,
  &:hover {
    .UserMenu-Pane {
      opacity: 1;
      transition: none;
      top: 100%;
    }
  }
}

.UserMenu-Pane {
  transition: opacity 500ms 1s, top 0s 1s;
  opacity: 0;

  right: 0;
  top: -100000px;
  color: black;
  display: flex;
  flex-direction: column;
  padding: 10px;
  min-width: 150px;
  position: absolute;
  border-radius: 10px;
  line-height: 1em;
  background-color: $white;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
  .fa {
    color: $red;
  }
  &::after {
    top: -3px;
    width: 10px;
    right: 25px;
    z-index: -1;
    height: 10px;
    content: ' ';
    display: block;
    position: absolute;
    box-sizing: border-box;
    transform: rotateZ(45deg);
    background-color: #e0e0e0;
  }
  button.UserMenu-Pane-Item {
    background: transparent;
    border: none;
  }
  .UserMenu-Pane-Item {
    &,
    &:link,
    &:active,
    &:visited {
      color: black;
      text-decoration: none;
    }
    flex: 1;
    border-radius: 0.2em;
    display: flex;
    font-size: 1.3em;
    padding: 5px;
    flex-direction: row;
    white-space: nowrap;
    cursor: pointer;
    &:hover {
      font-weight: 500;
    }
    .UserMenu-Pane-Item-Icon {
      width: 20px;
      margin-right: 10px;
    }
  }
}

.MesaComponent {
  .HelpTrigger,
  .Trigger.Active {
    color: $blue;
  }

  .ActionToolbar {
    a {
      color: $blue;
    }
    .ActionToolbar-Children {
      padding-right: 15px;
    }
  }
}

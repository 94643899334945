.MesaComponent {
  .EmptyState {
    height: 300px;
    width: 100%;

    .EmptyState-BodyWrapper {
      display: flex;
      height: 100%;
      width: 100%;
      justify-content: center;
      align-items: center;
      flex-direction: column;
    }
    .EmptyState-Body {
      flex: 0 0 auto;
      display: block;
      text-align: center;
      padding: 10px;

      .EmptyState-Icon {
        opacity: 0.1;
        font-size: 5rem;
        margin-bottom: 1rem;
      }
      h2 {
        margin-bottom: 1rem;
        text-align: center;
      }
      p {
        text-align: center;
        opacity: 0.85;
      }
      button {
        .icon {
          display: inline-block;
          margin: 0 0 0 5px;
        }
      }
    }
  }
}

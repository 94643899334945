.MesaComponent {
  .HeadingCell {
    .HeadingCell-Content {
      display: flex;
      width: 100%;
      flex-direction: row;
      flex-wrap: nowrap;
      align-items: center;
      position: relative;
      z-index: 1;

      .HeadingCell-Content-Aside {
        &:last-of-type {
          text-align: right;
        }
      }
      .HeadingCell-Content-Label {
        flex-basis: 0 0 auto;
      }
    }

    .HelpTrigger .icon {
      cursor: default;
    }
  }
}

/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
 DataTables sorting
*/
.dataTables_length,
.dataTables_filter,
.dataTables_info,
.dataTables_paginate {
  display: inline-block;
  margin-bottom: 5px;
  /*   margin-left: 3px; */
  /*   padding-bottom: 3px; */
}
.dataTables_filter,
.dataTables_paginate {
  /*    border-left: 2px solid #FFFFFF; */
  margin-left: 10px;
  padding-left: 10px;
}

table.dataTable th,
table.dataTable td {
  /*  border-left: 1px dotted #CCCCCC; */
  /*  border-right: 1px dotted #CCCCCC; */
}
table.dataTable th {
  font-weight: bold;
}

table.dataTable thead th.sortable {
  cursor: pointer;
}
table.dataTable thead th div.DataTables_sort_wrapper {
  position: relative;
  padding-right: 20px;
  padding-right: 20px;
}
table.dataTable thead th div.DataTables_sort_wrapper span {
  position: absolute;
  top: 50%;
  margin-top: -8px;
  right: 0;
}
table.dataTable td {
  border-bottom: 1px dotted #cccccc;
}
table.dataTable tr.odd {
  background-color: #eeeeff;
}
table.dataTable tr.even {
  background-color: #ffffff;
}

.ui-widget-header {
  padding: 3px;
  font-weight: normal;
}

.ui-widget {
  font-family: Arial, Helvetica, sans-serif;
}

.wdk-DatasetParam {
  @mixin disabled($modifer: 'disabled') {
    &__#{$modifer} {
      opacity: 0.3;
      pointer-events: none;
    }
  }

  &Section {
    @include disabled;
    display: flex;
    margin: 1em 0;
    max-width: 950px;
  }

  &SectionList {
    list-style: none;
  }

  &IdList {
    position: relative;
    display: inline-block;

    &__loading::before {
      content: '\f1ce';
      position: absolute;
      top: 0.35em;
      right: 0.35em;
      font-family: FontAwesome;
      -webkit-animation: fa-spin 2s infinite linear;
      animation: fa-spin 2s infinite linear;
    }
  }

  &URLField {
    width: 40em;
  }

  &FileParserList {
    list-style: none;
    display: flex;
    margin: 0.4rem 0;
  }

  &Label {
    flex: 1;
    margin-right: 0.1rem;
  }

  &Control {
    @include disabled;
    flex: 5;
  }
}

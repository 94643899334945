.vpdb-Srt {
  &--BulkDownloadLink {
    font-size: 1.3em;
    line-height: 200%;
    margin-top: 0;
  }

  &--SrtForms {
    margin-top: 3em;
  }

  &--IdsInput {
    margin: 2em;
  }
}

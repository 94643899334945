@import './eupathdb-Palette.scss';

a.eupathdb-BigButton,
input[type='submit'],
.filter-button input[type='submit'],
button.btn,
a.btn,
a:link.btn {
  /*  @include transition; */
  padding: 8px 12px;
  font-size: 1em;
  border-radius: 6px;
  line-height: 1em;
  color: $darkgray;
  background-color: #f5f5f7;
  border: 0.5px solid rgba(0, 0, 0, 0.1);
  border-bottom: 3px solid #999;
  margin: 5px 5px 5px 5px;
  text-decoration: none;

  &:hover {
    background-color: #f0f0f2;
    transform: scale(0.98);
    -webkit-transform: scale(0.98);
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.05);
  }

  .fa {
    margin-right: 10px;
    font-size: 1.2em;
    &.right-side {
      margin-left: 10px;
      margin-right: 0;
    }
    &.left-side {
      margin-right: 10px;
      margin-left: 0;
    }
  }

  &.btn-error {
    &:hover {
      background-color: $red;
      color: $white;
    }
  }
  &.btn-info {
    &:hover {
      background-color: $blue;
      color: $white;
    }
  }
  &.btn-success {
    &:hover {
      background-color: $green;
      color: $white;
    }
  }
  &.btn-hazard {
    &:hover {
      background-color: $orange;
      color: $white;
    }
  }
  &.btn-warning {
    /*    &:hover { @include buttonStyle($gold); } */
    text-shadow: 0px 0px 5px darken($gold, 25%);
  }
}

.filter-button input[type='submit'] {
  font-size: 1.5em;
}

.btn .ebrc-icon-download {
  margin-right: 0.75em;
}

/*
 * General button styling. Notice the inclusion of a.eupathdb-Button and
 * a.eupathdb-BigButton. These are used to make links look like buttons.
 * The enforcement of using it on an <a> element is to enforce accessibility.
 * See http://www.karlgroves.com/2013/05/14/links-are-not-buttons-neither-are-divs-and-spans/
 */
input[type='button'],
button {
  background-color: #e8e8e8;
  background-color: -webkit-linear-gradient(#f4f4f4, #dddddd);
  background-color: -o-linear-gradient(#f4f4f4, #dddddd);
  background-color: -moz-linear-gradient(#f4f4f4, #dddddd);
  background-color: linear-gradient(#f4f4f4, #dddddd);
  border: 1px solid #afafaf;
  border-radius: 2px;
  padding: 2px 6px;
  color: #3e3e3e;
  text-decoration: none;
}

a.btn:focus,
a.eupathdb-BigButton:focus,
input[type='submit']:focus,
input[type='button']:focus,
button:focus,
a.btn:hover,
a.eupathdb-BigButton:hover,
input[type='submit']:hover,
input[type='button']:hover,
button:hover {
  background-color: #ededed;
  background-color: -webkit-linear-gradient(#ededed, #dddddd);
  background-color: -o-linear-gradient(#ededed, #dddddd);
  background-color: -moz-linear-gradient(#ededed, #dddddd);
  background-color: linear-gradient(#ededed, #dddddd);
}

a.eupathdb-BigButton:focus,
a.btn:focus,
input[type='submit']:focus,
input[type='button']:focus,
button:focus {
  border-color: #717171;
}

a.eupathdb-BigButton:active,
a.btn:active,
input[type='submit']:active,
input[type='button']:active,
button:active {
  background-color: #ddd;
}

input[type='submit'][disabled],
input[type='button'][disabled],
button[disabled] {
  color: #aaa;
  background-color: #f4f4f4;
  border-color: #ccc;
  cursor: not-allowed;
}

a.eupathdb-BigButton,
input[type='submit'],
button[type='submit'] {
  font-size: 0.9rem;
}

.vpdb-PageDescription {
  font-size: 1.1em;

  &--DisplayName {
    font-weight: bold;
  }

  text-align: center;

  p {
    font-size: 1em;
    margin: 0.4em 0;
  }
}

/*
.CardList .wdk-RealTimeSearchBox__withHelp {
  padding-bottom: 10px;
}
*/
.CardList {
  align-items: center;
  background-color: $paleBlue;
  border-right: 4px solid $paleBlue;
  border-left: 4px solid $paleBlue;
  padding: 10px;
  display: flex;
  flex-flow: column nowrap;
  transition: all 0.4s;
  position: relative;
  width: 100%;

  &_Expandable {
    padding-top: 2em;
  }

  &_Searchable {
    padding-top: 10px;
  }

  .filters {
    display: flex;
    justify-content: center;
    width: 100%;
    align-items: center;
    > * {
      margin: 0 1.5em;
      min-width: 18em;
    }

    input.wdk-RealTimeSearchBoxInput {
      border-radius: 0.35em;
      padding: 0 1em;
      border-color: #cccccc;
      height: 36px;
    }
  }

  &__Entries {
    display: flex;
    justify-content: start;
    align-self: normal;
    position: relative;
    overflow-x: auto;
    overflow-y: hidden;
    /*    padding: 10px 0; */
    margin: 1em 0;
  }

  &_Expanded &__Entries {
    display: grid;
    grid-template-columns: repeat(auto-fill, 300px);
    grid-gap: 1.2rem;
    grid-auto-rows: 1fr;
    justify-content: center;
    grid-auto-flow: row;
  }

  button.CardList__ExpandButton {
    font-weight: 500;
    font-size: 120%;
    margin-left: auto !important;
    z-index: 1;
    position: absolute;
    top: 0.75em;
    right: 1em;
  }

  a.CardList__TableViewLink {
    font-weight: 500;
    font-size: 120%;
    position: absolute;
    top: 0.75em;
    left: 1em;
    z-index: 1;
  }

  &__FilterInput {
    max-width: 32em;
    align-self: center;
  }

  &_Empty,
  &_Loading {
    overflow: hidden;
  }

  &__EmptyIndicator,
  &__Spinner {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    height: 100%;
    color: #555;
  }

  &__Spinner {
    font-size: 8em;
  }

  &__EmptyIndicator {
    font-size: 3em;
    font-weight: 500;
    text-shadow: #b4b4b4 2px 2px 3px;
    background: #efefef80;
  }
}
.Card {
  flex: 0 0 300px;
  width: 300px;
  margin: 0 0.5em;
  font-size: 0.9rem;
  min-height: 250px;
  color: $textColor;
  border-radius: 20px;
  overflow: hidden;
  box-sizing: border-box;
  display: flex;
  flex-flow: column;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  background: #dcf1f8;
  background: -moz-radial-gradient(
    12% 5%,
    ellipse cover,
    #dcf1f8 0%,
    #ffffff 40%
  );
  background: -webkit-radial-gradient(
    12% 5%,
    ellipse cover,
    #dcf1f8 0%,
    #ffffff 40%
  );
  background: radial-gradient(ellipse at 12% 5%, #dcf1f8 0%, #ffffff 40%);

  &.disabled {
    opacity: 0.3;
    pointer-events: none;
    transition: all 0.5s;
    &:hover {
      opacity: 0.5;
      transform: scale(0.95);
    }
  }

  .row.ribbon {
    background-color: #e62f44;
    color: #fff;
    padding: 10px;
    text-align: center;
    justify-content: center;
    font-weight: 300;
    width: 100%;
    font-size: 1.1rem;
  }

  &.red-fade-bg {
    background: #edb2b7;
    background: -moz-radial-gradient(
      100% 0%,
      ellipse cover,
      #edb2b7 -20%,
      #ffffff 100%
    );
    background: -webkit-radial-gradient(
      100% 0%,
      ellipse cover,
      #edb2b7 -20%,
      #ffffff 100%
    );
    background: radial-gradient(ellipse at 100% 0%, #edb2b7 -20%, #ffffff 100%);
  }
  &.blue-fade-bg {
    background: #c4f0ff;
    background: -moz-radial-gradient(
      100% 0%,
      ellipse cover,
      #c4f0ff -20%,
      #ffffff 100%
    );
    background: -webkit-radial-gradient(
      100% 0%,
      ellipse cover,
      #c4f0ff -20%,
      #ffffff 100%
    );
    background: radial-gradient(ellipse at 100% 0%, #c4f0ff -20%, #ffffff 100%);
  }
  &.yellow-fade-bg {
    background: #ffedb8;
    background: -moz-radial-gradient(
      100% 0%,
      ellipse cover,
      #ffedb8 -20%,
      #ffffff 100%
    );
    background: -webkit-radial-gradient(
      100% 0%,
      ellipse cover,
      #ffedb8 -20%,
      #ffffff 100%
    );
    background: radial-gradient(ellipse at 100% 0%, #ffedb8 -20%, #ffffff 100%);
  }
  &.green-fade-bg {
    background: #dbf0cc;
    background: -moz-radial-gradient(
      100% 0%,
      ellipse cover,
      #dbf0cc -20%,
      #ffffff 100%
    );
    background: -webkit-radial-gradient(
      100% 0%,
      ellipse cover,
      #dbf0cc -20%,
      #ffffff 100%
    );
    background: radial-gradient(ellipse at 100% 0%, #dbf0cc -20%, #ffffff 100%);
  }
}
